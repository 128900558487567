import Table from "../components/table/Table"
import { supplyColumns } from '../components/table/Columns'
import { useMemo,useState,useEffect } from 'react'
import axios from 'axios'
import {Flexed,Flex,Img,Input,Datepick,Label,currency,Textarea,Button,PhoneNumber} from '../Shared'
import POnav from "./po/newpo/POnav"


const Supplies = () => {

    const [DATA, setDATA] = useState([])

    useEffect(() => {
        const getdata = async() => {
    await axios.get('https://highgroundapi.herokuapp.com/supplies')
    .then(res => {setDATA(res.data)})
    .catch(err => console.log(err)) 
    
        }
        getdata()
    },[])   


  return (
    <div>
        <Flexed>

            <Flex fontSize="calc(1.3rem + .5vw)" padding="20px 0px">Supplies Inventory</Flex>
            </Flexed>
            <POnav />
        <Table supplyColumns={supplyColumns} DATA={DATA} /></div>
  )
}

export default Supplies