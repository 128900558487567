import { AppBar, Badge, Toolbar } from '@mui/material'
import React, { useEffect,useState } from 'react'
import LOGO from '../assets/bgnew.png'
import { Flex, Flexed, Img, Wrap } from '../Shared'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { Paper,Box, IconButton, Typography, Avatar } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useContext } from "react";
import { UserContext } from "../context/UserContext";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import styled from 'styled-components';
import Navigat from './Navigat';


const Notdiv = styled.div`
borderTop:1px solid grey;

&:hover{
cursor:pointer;
}

`;

const Navbar = ({socket,setSearchTerm,searchTerm,setComp,comp}) => {

const [notopen, setNotopen] = useState(false)

const [notifications, setNotifications] = useState([])
   

  /*  useEffect(() => {
socket.on("getNotification",data => {
    setNotifications(prev =>[...prev,data])
})
    },[notopen])
*/
const {userInfo }  = useContext(UserContext)

  return (

<Wrap  style={{position:"sticky",top:"0",borderBottom:"1px solid var(--plsGreen)",paddingTop:"15px",backgroundColor:"var(--plsWhite)",zIndex:"50"}}>
    <Flexed height="45px">
        <Flex width="fit-content"  textAlign="left" height="100%">  <Img src={LOGO} height="100%"/></Flex>
        <Flex  height="100%"  display="flex" alignItems="center" justifyContent="flex-end"  >
        <Badge badgeContent={notifications.length}    sx={{
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "rgb(255,0,0,.7)",
     } }}>
        <NotificationsOutlinedIcon sx={{ fontSize: "30px",color:"var(--plsBlue)",}} onClick={() => setNotopen(!notopen)} />      
</Badge>
<Badge badgeContent={0}    sx={{
    "& .MuiBadge-badge": {
      color: "white",
      backgroundColor: "rgb(255,0,0,.7)",
     } }}>
      <EmailOutlinedIcon sx={{ fontSize: "30px",color:"var(--plsBlue)",marginLeft:"20px"}} onClick={() => setNotopen(!notopen)} />
</Badge>



  {notopen && <div style={{minWidth:"200px",width:"fit-content",border:"1px solid grey",position:"absolute",top:"37px",right:"-10px",backgroundColor:"white",zIndex:"100"}}>
{
    notifications.map(item => {
       return <Notdiv><div style={{backgroundColor:"var(--plsBlue)",color:"white",display:"flex",textAlign:"left",padding:"3px",display:"flex",alignItems:"center",justifyContent:"space-between",}}>{item.msg}<br />
       <div style={{padding:"0px 10px"}}><DeleteForeverOutlinedIcon  /></div></div>{item.errors.map(sy => <div onClick={() => {setComp("po");setSearchTerm("")}}> {sy.message}<br /></div>)}</Notdiv>
      
    })
} {notifications.length !== 0 ? <Notdiv style={{borderTop:"1px solid grey"}} onClick={() => {setNotifications([]);setNotopen(false)}}>Clear All</Notdiv>:<Notdiv style={{borderTop:"1px solid grey"}}>No Notifications</Notdiv>}
   
          </div>}
           </Flex>
           <Flex width="fit-content" style={{maxWidth:"245px",display:"flex",justifyContent:"flex-end",alignItems:"flex-end",height:"100%",marginLeft:"25px"}}>
            <Flex style={{width:"245px"}}  display="flex" justifyContent="flex-start" alignItems="center" bgColor="var(--plsBlue)" color="white" padding="5px">
           <Avatar sx={{color:"var(---plsBlue)",marginRight:"10px"}}>
<AccountCircleIcon />
</Avatar>
            <Typography>
                {userInfo.name}
            </Typography>
            </Flex>
            </Flex>
    </Flexed>
    <Navigat />
</Wrap>


  )
}

export default Navbar