import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { currency } from '../../Shared';

const TH = styled.th`
flex:${props => props.flex || "1"};
text-align:left;

`;


const TR = styled.tr`
display:flex;
color:${props => props.color || ""};
padding:5px;

`;


const TD = styled.td`
flex:${props => props.flex || "1"};
font-size:calc(.7rem + .5vw);

`;

 const Table = styled.table`
font-family: Arial, Helvetica, sans-serif;
border-collapse: collapse;
width: 100%;
margin:0px auto;

tbody tr:hover{
cursor:pointer;
}

thead tr{
   background-color:rgb(122,187,67,.4);
   padding:6px 5px;
}
 tbody tr{
    border-bottom:1px solid #648CA7;
 }

`;

const P = styled.p`
font-size:calc(1rem + .5vw);
padding:20px;
`;



const Tableset = ({headers,data,setComp,setCurrentSelectedPo,setSearchTerm}) => {
    const date = new Date();
    console.log(data)
  return (
 <>
 {data.length === 0 ? <P>There Are No PO's Matching This Criteria.</P> :
    <Table>
        <thead>
        <TR>
            <TH>PO#</TH>
            <TH>Vendor</TH>
            <TH>Due Date</TH>
            <TH>Ordered</TH>
            <TH flex="3" bg="yellow">Items</TH>
            <TH flex="1" bg="yellow">Cost</TH>
              </TR> 
        </thead>
        <tbody >
            {data.map(item => {
                let newdate = new Date()
                let color;
     if(newdate.toISOString().substring(0, 10) === item.dueDate.substring(0, 10)){color = "#F7006D";}
              return   <Link to={`po?id=${item._id}`}><TR key={item?.ponum} color={color} >
                <TD >{item?.ponum}</TD><TD>{item.vendor?.company.name}</TD>
                <TD><Moment format="MM/DD/YYYY">{item?.dueDate}</Moment></TD>
                <TD><Moment format="MM/DD/YYYY">{item?.createdAt}</Moment></TD>
                <TD flex="3">
                {item.items?.map((item,index) => {return  <div key={index}>{item?.quantity}&nbsp;{item?.orderby}&nbsp;of&nbsp; {item?.itemname}<br /></div>})}</TD><TD >{currency(item.totalcost)}</TD></TR></Link>})}</tbody></Table>
    } 
    </>
  )
}

export default Tableset