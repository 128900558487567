import styled from "styled-components"
import { Flex, Flexed, Wrap} from "../Shared"
import { useNavigate } from "react-router-dom"



const Navigat = () => {
const nav = useNavigate()
  return (
    <Wrap  color="white" position="sticky" top="56px" style={{zIndex:"-1"}}>
        <Flexed bgColor="var(--plsGreen)" padding="5px 0px">
            <Flex>Dashboard</Flex>
            <Flex>Customers</Flex>
            <Flex>Forms</Flex>
            <Flex>Estimating</Flex>
            <Flex>Mailing</Flex>
            <Flex onClick={() => nav("/shipping")}>Shipping</Flex>
            <Flex onClick={() => nav("/po")}>PO</Flex>
            <Flex>Schedule</Flex>
            <Flex>Inventory</Flex>
            <Flex>Admin</Flex>
        </Flexed>
        </Wrap>
  )
}

export default Navigat