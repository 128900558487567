import styled from 'styled-components'
import DatePicker from "react-datepicker";
//import Select from 'react-select';
//import "react-datepicker/dist/react-datepicker.css";
//import { MdDelete,MdOutlinePrint,MdOutlineSave,MdEditNote,MdEditOff } from 'react-icons/md'
//import { RiUserAddLine } from 'react-icons/ri'

//import { IoDuplicateOutline } from 'react-icons/io5'
//import TextareaAutosize from 'react-textarea-autosize';
////["2xs","xs","sm","lg","xl","2xl","1x","2x","3x","4x","5x","6x","7x","8x","9x","10x"].


export const Flexed = styled.div`
display: ${props => props.display || "flex"};
justify-content: ${props => props.justifyContent || ""};
flex-direction: ${props => props.flexDirection || ""};
align-items: ${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
width: ${props => props.width || "100%"};
padding: ${props => props.padding || ""};
background-color: ${props => props.bgColor || ""};
border: ${props => props.border || ""};
gap: ${props => props.gap || "0px"};
font-size:${props => props.fontSize || ""};
font-weight:${props => props.fontWeight || ""};
margin: ${props => props.margin || "0px"};
height: ${props => props.height || ""};
border-bottom: ${props => props.borderBottom || ""};
color: ${props => props.color || ""};
padding: ${props => props.padding || "0px"};
top: ${props => props.top || ""};

`;

export const Flex = styled.div`
flex: ${props => props.flex || "1"};
width: ${props => props.width || "100%"};
display: ${props => props.display || ""};
justify-content: ${props => props.justifyContent || ""};
align-items: ${props => props.alignItems || ""};
text-align: ${props => props.textAlign || "center"};
height: ${props => props.height || ""};
padding: ${props => props.padding || ""};
position:${props => props.position || "relative"};
border: ${props => props.border || ""};
background-color: ${props => props.bgColor || ""};
color: ${props => props.color || ""};
border-bottom: ${props => props.borderBottom || ""};
min-width:${props => props.minWidth || ""};
font-size:${props => props.fontSize || ""};
font-weight:${props => props.fontWeight || ""};
margin: ${props => props.margin || "0px"};
`;

export const Wrap = styled.div`
color: ${props => props.color || ""};
background-color: ${props => props.bgColor || ""};
display: ${props => props.display || ""};
justify-content: ${props => props.justifyContent || ""};
align-items: ${props => props.alignItems || ""};
position: ${props => props.position || "relative"};
width: ${props => props.width || "100%"};
top: ${props => props.top || ""};
left: ${props => props.left || ""};
right: ${props => props.right || ""};
height: ${props => props.height || ""};
padding: ${props => props.padding || ""};
border: ${props => props.border || ""};
margin: ${props => props.margin || ""};
text-align: ${props => props.textAlign || ""};
overflow:${props => props.overflow || ""};

`;

export const Img = styled.img`
position: ${props => props.position || "relative"};
width: ${props => props.width || ""};
height: ${props => props.height || ""};
color: ${props => props.color || ""};
z-index:-1;

`;

export const Label = styled.label`
font-size:calc(.6rem + .5vw);
width: ${props => props.height || "100%"};



`;

export const Input = styled.input`
position:relative;
border-radius:5px;
width: ${props => props.width || "100%"};
border:1px solid rgb(0,0,0,.3);
outline: none;
font-size:calc(.4rem + .5vw);

padding:8px 5px;

:focus{
  border:2px solid var(--plsGreen);
}

`;

export const Selectbox = styled.select`
position:relative;
border-radius:5px;
width: ${props => props.height || "100%"};
border:1px solid rgb(0,0,0,.3);
outline: none;
font-size:calc(.5rem + .5vw);
padding:8px 5px;

:focus{
    border:2px solid var(--plsGreen);
  }

`;






export const Tooltip = styled.div`
position:absolute;
width:100%;
display:none;



`;





export const Textarea = styled.textarea`
min-height:100%;
width:100%;
outline:none;
border:1px solid #c8c8c8;
font-size:calc(.6rem + .5vw);


resize:none;
:focus{
    border:2px solid var(--plsGreen);
  }

`;


export const Button = styled.button`

padding:${props => props.padding || "6px 15px"};
border:${props => props.border || "none"};
background-color:${props => props.bgcolor || "#C3D2DB"};
color:${props => props.color || "inherit"};
width:${props => props.width || ""};
display: inline-block;
`;


export const Datepick = styled(DatePicker)`
position:relative;
border-radius:5px;
width: ${props => props.height || ""};
border:1px solid rgb(0,0,0,.3);
outline: none;
font-size:calc(.4rem + .5vw);
padding:8px 5px;

`;



export const PhoneNumber = (value) => {
if(!value || value === undefined){
return value;
    }else{
        value = value.replace(/[^\d]/g, '');
        return `(${value.slice(0,3)}) ${value.slice(3,6)} - ${value.slice(6,10)}`;
    }

}

export const currency = (num) => {
    return '$ ' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }



