import styled from 'styled-components'
import ReactDOM from "react-dom";


export const BackdropArea = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;

`;



const Backdrop = (props) => {
  return ReactDOM.createPortal(
    <BackdropArea  onClick={props.onClick}></BackdropArea>,
    document.getElementById("backdrop")
  );
};

export default Backdrop;