import React, { useRef,useEffect ,useState} from "react";
import {currency,Flexed,Flex} from '../../Shared'
import PLS from '../../assets/plsprint.png'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

import ReactToPrint from "react-to-print";


export default function PrintComponent({id,to,items,total,closedd}) {
  let componentRef = useRef();
 
const [message, setMessage] = useState("")
  useEffect(() => {
const getpoinfo = async() => {

}

getpoinfo()
  })
 
 

  return (
    <>
      <div id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <LocalPrintshopOutlinedIcon style={{fontSize:"calc(1.5rem + .5vw)"}}/>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <ComponentToPrint id={id} to={to} closedd={closedd} items={items} total={total} message={message} ref={(el) => (componentRef = el)} />
        </div>
      </div>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
    console.log(props)
  
  }

  render() {
    return (
      <div style={{border:"1px solid red",minHeight:"100vh",position:"relative",padding:"40px 40px 0px 20px"}}>
        {this.props.closedd && <div style={{width:"100%",height:"100%",backgroundColor:"rgb(255,255,255,.0)",position:"absolute",top:"0",left:"0",zIndex:"1",color:"rgb(0,0,0,.1)",display:"flex",justifyContent:"center",alignItems:"center"}}>
  <div style={{transform:"rotate(-45deg)",fontSize:"calc(4rem + .5vw)",letterSpacing:"50px"}}>CLOSED</div>
  </div>}
        <div style={{display:"flex"}}>
            <div style={{flex:"1.2"}}><img src={PLS} alt="" style={{height:"68%"}}/></div>
            <div style={{flex:"1",fontSize:".8rem"}}>{}
            4250 118th Ave N<br />Clearwater,Fl 33762<br />Phone: 727-573-1763<br />Fax: 727-573-2662<br />www.plsprint.com
            </div>
            <div style={{flex:"1.9",fontSize:"2rem", fontWeight:"bold",textAlign:"center",marginTop:"-8px",padding:"0"}}>Purchase Order</div>
        </div>
        
        <div style={{display:"flex",marginTop:"20px"}}>
            <div style={{flex:"1.3",fontSize:".9rem", fontWeight:"bold"}}>Vendor<br /><span style={{fontSize:".9rem", fontWeight:"normal"}}>{this.props.to?.company?.name}<br />{this.props.to?.address.address1}<br />{this.props.to?.address.city},{this.props.to?.address.state} {this.props.to?.address.zip}<br /></span></div>
            <div style={{flex:"1",fontSize:".9rem", fontWeight:"bold"}}>Ship To <br /><span style={{fontSize:".9rem", fontWeight:"normal"}}>PLS Print<br />4250 118th Ave N<br />Clearwater,Fl 33762<br /></span></div>
            <div style={{flex:".2",fontSize:".9rem", fontWeight:"bold"}}></div>
            <div style={{flex:"1",fontSize:".9rem", fontWeight:"bold",}}>Order Date<br /><span style={{fontSize:"1rem", fontWeight:"normal"}}>12/20/2022</span><br /> <div style={{marginTop:"7px"}}>PO No<br /><span style={{fontSize:"1rem", fontWeight:"normal"}}>{this.props.id}</span></div></div>
            <div style={{flex:"1",fontSize:".9rem", fontWeight:"bold"}}>Due Date<br /><span style={{fontSize:"1rem", fontWeight:"normal"}}>12/20/2022</span><br /> <div style={{marginTop:"7px"}}>Ordered By<br /><span style={{fontSize:"1rem", fontWeight:"normal"}}>Employee</span></div></div>
        </div>
     
<div style={{height:"61.5vh",border:"1px solid black",marginTop:"20px",position:"relative"}}>

<div style={{display:"flex",color:"black",boxSizing:"border-box",position:"relative",background:"rgb(0,0,0,.1)",borderBottom:"1px solid black"}}>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Quantity</div>
<div style={{flex:".9",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Order Unit</div>
<div style={{flex:"2",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px",borderRight:"1px solid black"}}>Item</div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Job </div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Unit Price</div>
<div style={{flex:".9",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>Pricing Unit</div>
<div style={{flex:".7",textAlign:"center",padding:"7px 5px"}}>Cost</div>
</div>

<div style={{display:"flex",color:"black",boxSizing:"border-box",height:"58vh",position:"relative"}}>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}></div>
<div style={{flex:".9",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}></div>
<div style={{flex:"2",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px",borderRight:"1px solid black"}}></div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}> </div>
<div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}> </div>
<div style={{flex:".9",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}> </div>
<div style={{flex:".7",textAlign:"center",padding:"7px 5px"}}></div>


</div>
<div style={{width:"100%",position:"absolute",top:"30px",left:"0",height:"60vh",background:"transparent"}}>
{

this.props.items?.map(item => {


  
 return (
  <div key={item.id} style={{display:"flex",color:"black",boxSizing:"border-box",fontSize:".7rem",alignItems:"flex-start"}}>
      <div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>{item.quantity}</div>
      <div style={{flex:".9",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>{item.orderby}</div>
  <div style={{flex:"2",textAlign:"left",borderRight:"1px solid black",padding:"7px 5px",height:"100%"}}>{item.itemname}
  {item.notes > "" && <div>***&nbsp;{item.notes}</div>}
  
  </div>
  <div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>{item.jobnum}</div>


  <div style={{flex:".7",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>{currency(item.unitprice)}</div>
  <div style={{flex:".9",textAlign:"center",borderRight:"1px solid black",padding:"7px 5px"}}>{item.priceunit}</div>
  <div style={{flex:".7",textAlign:"right",padding:"7px 5px"}}>{currency(item.extprice)}</div>
  </div>
)

  })
  
}

</div>


</div>
<div  style={{display:"flex",color:"black",boxSizing:"border-box",fontSize:".7rem",alignItems:"flex-start"}}>
  <div style={{flex:"5",textAlign:"left",height:"100%"}}></div>
  <div style={{flex:".9",textAlign:"right",padding:"7px 5px"}}>Total:&nbsp;</div>
  <div style={{flex:".7",textAlign:"right",padding:"7px 5px"}}>{currency(this.props.total)}</div>
  </div>

<Flexed alignItems="flex-end">
  <Flex textAlign="left">Thank You<br />WE Appreciate<br/>Your Business</Flex>
  <Flex textAlign="right">Received By:</Flex>
  <Flex flex="1.5" style={{borderBottom:"1px solid black"}}></Flex>
  <Flex textAlign="right">Print:</Flex>
  <Flex flex="1.5" style={{borderBottom:"1px solid black"}}></Flex>
  </Flexed>
      </div>
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
