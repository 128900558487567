import React, { useEffect,useState } from 'react'
import Navbar from '../../components/Navbar'
import POnav from '../po/newpo/POnav'
import { useParams  } from 'react-router-dom'
import axios from 'axios'
import { Label,Input,Flexed,Flex,Selectbox, Textarea, Button } from '../../Shared'

const Supplyitem = () => {

  const { supplyid } = useParams()

  const [item, setItem] = useState({})
  const [newquantity, setNewquantity] = useState();
  const [add, setAdd] = useState();
  const [newhistory, setNewhistory] = useState("");
  const [newjob, setNewjob] = useState("");
  const [newreason, setNewreason] = useState("");
  const [which, setWhich] = useState("");

  const orderbylist= ["Sheets","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]
const priceunits= ["PER M","CWT","Sheet","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]

  useEffect(() => {
    const getitem = async() => {
      await axios.post(`http://localhost:5003/supplies/item/${supplyid}`)
      .then(res => setItem(res.data))
      .catch(err => console.log(err))
    }
    getitem()
  },[])

  const addto = (e) => {
    setAdd(parseInt(e.target.value))
    setNewquantity(parseInt(e.target.value) + parseInt(item.instock))
    setWhich(" added to inventory: ")
  }

  const removefrom = (e) => {
    setAdd(parseInt(e.target.value))
       setNewquantity(parseInt(item.instock) - parseInt(e.target.value))
       setWhich(" removed from inventory: ")
      }

const updateitem = () => {

setItem({...item,history:add+which+newreason+' - New Instock: '+newquantity,instock:newquantity})

}


console.log(item)
  return (
    <div>
      <div style={{fontSize:"calc(1.2rem + .5vw)",textAlign:"center",padding:"20px 0px"}} >Supply Inventory</div> 
<POnav />
<Flexed gap="10px">
  <Flex textAlign="left" flex="3"><Label>Item</Label><Input type="text" value={item.name} onChange={e => setItem({...item,name:e.target.value})}/></Flex>
  <Flex textAlign="left" flex=".5"><Label>Item ID</Label><Input type="text" value={item.stockid} onChange={e => setItem({...item,stockid:e.target.value})} disabled/></Flex>
  <Flex textAlign="left"><Label>Category</Label><Selectbox value={item.category} onChange={e => setItem({...item,category:e.target.value})}><option value="test">test</option><option value="Bindery">Bindery</option></Selectbox></Flex>
  <Flex textAlign="left"><Label>Type</Label><Selectbox value={item.type} onChange={e => setItem({...item,type:e.target.value})}><option value="paper">Paper</option><option value="ink">Ink</option><option value="supplies">Supplies</option><option value="Ink">Ink</option></Selectbox></Flex>
</Flexed>
<Flexed gap="10px" padding="10px 0px">
  <Flex textAlign="left" flex="1"><Label>Size</Label><Input type="text" value={item.size} onChange={e => setItem({...item,size:e.target.value})}/></Flex>
  <Flex textAlign="left" flex="1"><Label>Orderby</Label><Selectbox value={item.defaultorderby} onChange={e => setItem({...item,defaultorderby:e.target.value})}>
    {orderbylist.map(item => {
   return   <option value={item}>{item}</option>
    })}
    
    </Selectbox></Flex>
  <Flex textAlign="left"><Label>Cost By</Label><Selectbox value={item.defaultpriceunit} onChange={e => setItem({...item,defaultpriceunit:e.target.value})}>  {priceunits.map(item => {
   return   <option value={item}>{item}</option>
    })}</Selectbox></Flex>
  <Flex textAlign="left"><Label>Cost</Label><Input type="text" value={item.price} onChange={e => setItem({...item,price:e.target.value})} /></Flex>
  <Flex textAlign="left"><Label>In Stock</Label><Input type="text" value={item.instock} onChange={e => setItem({...item,instock:e.target.value})} disabled/><br /><p style={{color:"rgb(255,0,0,.8)",fontWeight:"bold"}} >&nbsp;{isNaN(newquantity) ? '' : 'New quantity: '+newquantity}</p></Flex>
</Flexed>
<Flexed alignItems="center" gap="10px">
  <Flex flex=".8"><Label>Add to Inventory</Label><Input type="number" onChange={addto}/><br /></Flex>
  <Flex flex=".8"><Label>Remove from Inventory</Label><Input type="number" onChange={removefrom}/><br /></Flex>
  <Flex flex=".8"><Label>Job</Label><Input type="text"/><br /></Flex>
  <Flex flex="1"><Label>Location</Label><Input type="number"/><br /></Flex>
</Flexed>
<Flexed alignItems="center" gap="10px"  padding="15px 0px">
<Flex textAlign="left" flex="2"><Label>Reason</Label><Input type="text" onChange={ e => setNewreason(e.target.value)}/></Flex>
</Flexed>
<Flexed alignItems="center" padding="10px 0px">
  <Flex><Label>Notes</Label><Textarea rows="5"/></Flex>  
  <Flex flex=".8"><Button onClick={updateitem}>Update Item</Button><br /><br /><Button>Delete Item</Button></Flex>
  <Flex flex="1.5"><Label>History</Label><Textarea rows="5"  readonly/></Flex>
</Flexed>





    </div>
  )
}

export default Supplyitem