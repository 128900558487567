import React, { useEffect,useState } from 'react'
import Navbar from '../../../../components/Navbar'
import POnav from '../POnav'
import { Link, useParams,useNavigate  } from 'react-router-dom'
import axios from 'axios'
import { Label,Input,Flexed,Flex,Selectbox, Textarea, Button } from '../../../../Shared'



const Inv = () => {

  const { supplyid } = useParams()

  const [item, setItem] = useState({})
  const [newquantity, setNewquantity] = useState();
  const [add, setAdd] = useState();
  const [newhistory, setNewhistory] = useState([]);
  const [newjob, setNewjob] = useState("");
  const [newreason, setNewreason] = useState("");
  const [which, setWhich] = useState("");
  const [jobnum, setJobnum] = useState("");
  const [location, setLocation] = useState("");
  const [reason, setReason] = useState("");
  const [notes, setNotes] = useState("");
  const [more, setMore] = useState([]);
  const orderbylist= ["Sheets","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]
const priceunits= ["PER M","CWT","Sheet","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]
let navigate = useNavigate();  
const getMore = async(id) => {
  await axios.get(`http://localhost:5003/inv/same/${id}`)
  .then(res => {setMore(res.data)})
  .catch(err => console.log(err))
}

  useEffect(() => {
    let morestuff;
    const getitem = async() => {
      await axios.get(`http://localhost:5003/inv/item/${supplyid}`)
      .then(res => {setItem(res.data);setJobnum(res.data.jobnum);setLocation(res.data.location);setNotes(res.data.notes);setNewhistory(res.data.history);getMore(res.data.stockid)})
      .catch(err => console.log(err))
    }
  
    getitem()

  },[supplyid])

  const addto = (e) => {
    setAdd(parseInt(e.target.value))
    setNewquantity(parseInt(e.target.value) + parseInt(item.currentInventory))
    setWhich(" added to inventory: ")
  }

  const removefrom = (e) => {
    setAdd(parseInt(e.target.value))
       setNewquantity(parseInt(item.currentInventory) - parseInt(e.target.value))
       setWhich(" removed from inventory: ")
      }

const updateitem = () => {



}

const saveform = (e) => {
  e.preventDefault()
}
console.log()

  return (
    <div>
      
      <div style={{fontSize:"calc(1.2rem + .5vw)",textAlign:"center",padding:"20px 0px"}} >Paper Item</div> 
<POnav />
<Flexed gap="10px" style={{borderTop:"1px solid grey"}} padding="20px 0px">
  <Flex textAlign="left" flex="3"><Label>Item</Label><Input type="text" value={item.paperid?.name} disabled/></Flex>
  <Flex textAlign="left" flex=".5"><Label>Item ID</Label><Input type="text" value={item.paperid?.stockid}  disabled/></Flex>
  <Flex textAlign="left"><Label>Category</Label><Input type="text" value={item.paperid?.category}  disabled/></Flex>
  <Flex textAlign="left"><Label>Type</Label><Input type="text" value={item.paperid?.kind}  disabled/></Flex>
</Flexed>
<Flexed gap="10px" padding="10px 0px">
  <Flex textAlign="left" flex="1"><Label>Size</Label><Input type="text" value={item.size}  /></Flex>
  <Flex textAlign="left" flex="1"><Label>Orderby</Label><Input type="text" value={item.orderby}  disabled/></Flex>
  <Flex textAlign="left"><Label>Cost</Label><Input type="text" value={item.paperid?.price} /></Flex>
  <Flex textAlign="left"><Label>Cost By</Label><Input type="text" value={item.paperid?.defaultpriceunit}  disabled/></Flex>
  <Flex textAlign="left"><Label>Cost </Label><Input type="text" value={item.cost}  disabled/></Flex>
 
</Flexed>
<form onSubmit={saveform}>
<Flexed alignItems="center" gap="10px">
<Flex flex=".8"><Label>Add Inventory</Label><Input type="number" onChange={addto}/><br /></Flex>
  <Flex flex=".8"><Label>Remove Inventory</Label><Input type="number" onChange={removefrom}/><br /></Flex>
  <Flex flex=".8"><Label>Job</Label><Input type="text" value={newjob} onChange={e => setNewjob(e.target.value)} /><br /></Flex>
<Flex flex=".8"><Label><u>Job</u></Label><br />{jobnum}<br /></Flex>
<Flex flex=".8"><Label><u>PO</u></Label><br /><Link to={`/purchase/po?id=${item.po?._id}`}>{item.po?.ponum}</Link></Flex>
<Flex flex="1"><Label><u>Location</u></Label><br />{location}<br /></Flex>
<Flex flex="1"><Label>Move To</Label><Input type="text" value={location} onChange={e => setLocation(e.target.value)}/><br /></Flex>
 
 
</Flexed>
<Flexed alignItems="center" gap="10px"  padding="15px 0px">
 <Flex textAlign="left"><Label>In Stock</Label><Input type="text" value={item.currentInventory?.toLocaleString('en-US')} disabled/><br /><p style={{color:"rgb(255,0,0,.8)",fontWeight:"bold"}} >&nbsp;{isNaN(newquantity) ? '' : 'New quantity: '+newquantity}</p></Flex>
 <Flex flex="3"></Flex>
 <Flex>Physical Count<Input type="number" /></Flex>

</Flexed>
<Flexed alignItems="center" gap="10px"  padding="15px 0px">
<Flex textAlign="left" flex="2"><Label>Reason</Label><Input type="text" onChange={ e => setReason(e.target.value)} required/></Flex>
</Flexed>
<Flexed alignItems="center" padding="10px 0px">
  <Flex><Label>Notes</Label><Textarea rows="6" value={notes} onChange={e => setNotes(e.target.value)}/></Flex>  
  <Flex flex=".8"><Button type="submit" >Update Item</Button><br /><br /><Button>Delete Item</Button></Flex>
 
</Flexed>

<br />
<div style={{borderTop:"1px solid grey"}}>
  <Flexed>
    <Flex textAlign="left"><u>History</u>
{newhistory?.reverse().map(item => {
 return  <Flexed>
  <Flex textAlign="left">{item.description}</Flex>
  <Flex></Flex>
  </Flexed>
})}</Flex>
    <Flex textAlign="left" ><u>More of this item</u>
    <Flexed padding="5px 0px" bgColor="rgb(177,189,66,.4)">
    <Flex textAlign="left" >Inv ID</Flex>
    <Flex textAlign="left" >Size</Flex>
    <Flex textAlign="left"  flex="3">Item</Flex>
    <Flex textAlign="left" >Location</Flex>
  </Flexed>
{more.filter(mor => mor._id !== item._id).map(mo => {
  return <Flexed padding="5px 0px" borderBottom="1px solid grey" onClick={() => navigate(`/inventory/${mo._id}`)}>
    <Flex textAlign="left" >{mo.invid}</Flex>
    <Flex textAlign="left" >{mo.size}</Flex>
    <Flex textAlign="left"  flex="3">{mo.paperid.name}</Flex>
    <Flex textAlign="left" >{mo.location}</Flex>
  </Flexed>
})}
</Flex>
  </Flexed>


</div>
</form>
<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    </div>
  )
}

export default Inv