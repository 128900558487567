import { Routes ,Outlet,Link} from 'react-router-dom'
import {Flexed,Flex,Img,Input,Datepick,Label,currency,Textarea,Button,PhoneNumber,Wrap} from '../../../Shared'
import MoreOutlinedIcon from '@mui/icons-material/MoreOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import LineStyleOutlinedIcon from '@mui/icons-material/LineStyleOutlined';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { Tooltip } from '@mui/material';

const POnav = ({setAddvendor}) => {
  return (
<Flexed justifyContent="flex-end">
<Flex margin="0px 10px" padding="10px 0px" flex="0"><Link to="/purchase"><Tooltip sx={{fontSize:"2rem"}} title={<h4 style={{fontSize:".8rem"}}>Dashboard</h4>} arrow><LineStyleOutlinedIcon className="icon" style={{fontSize:"2rem"}} /></Tooltip></Link></Flex>
<Flex margin="0px 10px" padding="10px 0px" flex="0"><Link to="/purchase/new"><Tooltip title={<h4 style={{fontSize:".8rem"}}>Add PO</h4>} arrow><MoreOutlinedIcon className="icon" style={{fontSize:"2rem"}} /></Tooltip></Link></Flex>
<Flex margin="0px 10px" padding="10px 0px" flex="0"><Tooltip title={<h4 style={{fontSize:".8rem"}}>Add Vendor</h4>} arrow><GroupAddOutlinedIcon className="icon" style={{fontSize:"2rem"}} onClick={() => setAddvendor(true)} /></Tooltip></Flex> 
<Flex margin="0px 10px" padding="10px 0px" flex="0"><Link to="/inventory/movement"><Tooltip title={<h4 style={{fontSize:".8rem"}}>Inventory Movement</h4>} arrow><TrackChangesIcon className="icon" style={{fontSize:"2rem"}} /></Tooltip></Link></Flex>
</Flexed>
  )
}

export default POnav