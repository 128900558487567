import styled from 'styled-components'



export const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${props => props.cols || ""};
border:1px solid var(--plsGrey);
  padding:10px 5px;
  grid-template-areas:${props => props.grid || ""};
  justify-content: start;


 .vendor{grid-area:vendor;position:relative;}
 .podate{grid-area:podate;position:relative;}
 .duedate{grid-area:duedate;position:relative;}
 .ponum{grid-area:ponum;position:relative;}
 .address1{grid-area:address1;position:relative; }
 .address2{grid-area:address2;position:relative;}
 .vendorid{grid-area:vendorid;position:relative;span:3;}
 .city{grid-area:city;position:relative }
 .state{grid-area:state;position:relative }
 .zip{grid-area:zip;position:relative }
 .type{grid-area:type;position:relative;}
 .phone{grid-area:phone;position:relative;align-self: top}
 .website{grid-area:website;position:relative;align-self: start}
 .fax{grid-area:fax;position:relative;align-self: start}
 .notes{grid-area:notes;position:relative;min-height:100px;overflow:hidden;border:1px solid #c8c8c8;max-height:100%;}


`;

export const ItemListArea = styled.div`

position:relative;
margin:${props => props.margin || "15px 0px"};
border:${props => props.border || ""};
overflow-x:scroll;



`;
export const Items = styled.div`
width:3300px;
margin:${props => props.margin || "15px 0px"};
border:${props => props.border || ""};
height:50px;
overflowY:auto;
overflowX:none;

`;

