import React, { useEffect, useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Textarea } from '../../Shared'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
const Addvendor = ({tempvendor,onCancel,setAddedVendor}) => {
  const initialvendor = {
    address:{
      address1:"",
      address2:"",
      city:"",
      state:"",
      zip:""
    },
    company:{
      company_id:"",
      dba:"",
      name:tempvendor
    },
    numbers:{
      fax:"",
      main:"",
      other:""
    },
    type:"",
    website:"",
    _id:"",
    notes:""
  }
  const [vendorTypes, setVendorTypes] = useState([])
  const [selectedItem, setSelectedItem] = useState(initialvendor)

console.log(tempvendor)
useEffect(() => {

      const getVendorTypes = async() => {
          await axios.get("http://localhost:5003/list/vendorTypes")
           .then(response => setVendorTypes(response.data.vendor.items))
           .catch(err => console.log(err))
           }
      getVendorTypes();
     
},[]) 

const submit = async(e) => {
  e.preventDefault();

  const r = await axios.post("http://localhost:5003/vendor/add",selectedItem)
           .then(response => {

console.log(response.data.vendor)
           if(setAddedVendor){
            setAddedVendor(response.data.vendor)
           }
       toast.success("Vendor Added")
           onCancel()
           })
           .catch(err =>   console.log(err))//toast.error(err.response.data.message))

}
console.log(selectedItem)
  return (
   <form>
    <ToastContainer position="bottom-center" theme="dark"/>
<Flexed gap="10px" padding="10px 0px">
  <Flex  textAlign="left"><Label>Vendor Name</Label><br /><Input type="text" value={selectedItem.company?.name} 
  onChange={e => setSelectedItem({...selectedItem,company:{...selectedItem.company,name:e.target.value}})}
  required/></Flex>
  <Flex  textAlign="left"><Label>Vendor DBA</Label><br /><Input type="text" /></Flex>

  <Flex  textAlign="left" flex="1"><Label>Item Type</Label><br />
  <Input type="text" list="vendorTypes"  value={selectedItem.type} onChange={e => setSelectedItem({...selectedItem,type:e.target.value})} placeholder="Select Item" required/>
<datalist id="vendorTypes">
{
vendorTypes?.map(vendor => (
<option key={vendor.value} value={vendor.value} />
))
}
</datalist></Flex>
<Flex flex="1"></Flex>
</Flexed>
<Flexed gap="10px"  padding="10px 0px">
  <Flex flex="1.5" textAlign="left"><Label>Address 1</Label><br /><Input type="text" value={selectedItem.address1}   onChange={e => setSelectedItem({...selectedItem,address:{...selectedItem.address,address1:e.target.value}}) }/></Flex>
  <Flex  textAlign="left" flex="1.5"><Label>Address 2</Label><br /><Input type="text" value={selectedItem.address2} onChange={e => setSelectedItem({...selectedItem,address:{...selectedItem.address,address2:e.target.value}})} /></Flex>
  <Flex  textAlign="left"><Label>City</Label><br /><Input type="text" value={selectedItem.city} onChange={e => setSelectedItem({...selectedItem,address:{...selectedItem.address,city:e.target.value}})} /></Flex>
  <Flex  textAlign="left"><Label>State</Label><br /><Input type="text" value={selectedItem.state} onChange={e => setSelectedItem({...selectedItem,address:{...selectedItem.address,state:e.target.value}})} /></Flex>
  <Flex  textAlign="left"><Label>Zip</Label><br /><Input type="text" value={selectedItem.zip} onChange={e => setSelectedItem({...selectedItem,address:{...selectedItem.address,zip:e.target.value}})} /></Flex>
</Flexed>
<Flexed gap="10px"  padding="10px 0px">
  <Flex  textAlign="left"><Label>Main Phone</Label><br /><Input type="text" value={selectedItem.main} onChange={e => setSelectedItem({...selectedItem,numbers:{...selectedItem.numbers,main:e.target.value}})} /></Flex>
  <Flex  textAlign="left"><Label>Fax</Label><br /><Input type="text" value={selectedItem.fax} onChange={e => setSelectedItem({...selectedItem,numbers:{...selectedItem.numbers,fax:e.target.value}})} /></Flex>
  <Flex  textAlign="left"><Label>Website</Label><br /><Input type="text" value={selectedItem.website} onChange={e => setSelectedItem({...selectedItem,website:e.target.value})} /></Flex>
  <Flex></Flex>
  
</Flexed>
<Flexed alignItems="flex-end" gap="10px"  padding="10px 0px">
 
  <Flex  textAlign="left" flex="2"><Label>Vendor Notes<br /><Textarea value={selectedItem.notes} rows="5" onChange={e => setSelectedItem({...selectedItem,notes:e.target.value})}></Textarea></Label></Flex>
  <Flex></Flex>
<Flex  textAlign="left" flex="1">
<Button padding="8px 20px" width="100%" onClick={submit} bgcolor="#7ABD42"  color="white" style={{marginRight:"25px"}} type="button" >Add Vendor</Button>
</Flex>
</Flexed>
   </form>
  )
}

export default Addvendor