import styled from 'styled-components'
import {useEffect, useState } from 'react'
import { Flexed, Flex, Input, Wrap } from '../../Shared'
import MoreOutlinedIcon from '@mui/icons-material/MoreOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import LineStyleOutlinedIcon from '@mui/icons-material/LineStyleOutlined';
import Dash from './newpo/Dash';
import PO from './PO'
import Posearch from './Posearch';
import Addpo from './Addpo'
import Po from '../po/PO'



const Header = styled.h2`
text-align:center;
padding:20px 0px 20px 0px;

`;

const Ribbon = styled.div`
padding:0 0 20px 0;
margin:0;


`;









const Purchaseorders = ({errorset,errors,setSearchTerm,setComp,searchTerm,comp}) => {

const [currentSelectedPo, setCurrentSelectedPo,] = useState({})
const [ponotsaved, setPonotsaved,] = useState(true)

useEffect(() => {
if(searchTerm > ""){
  setComp("")
}
},[searchTerm])



  return (
    <>
    <Wrap style={{borderBottom:"2px solid var(--plsBlue)",position:"sticky",top:"92px",border:"1px solid blueq",backgroundColor:"#EDEEF2",zIndex:"5"}}>
    <Header>Purchase Orders</Header>
    <Ribbon >
        <Flexed justifyContent="space-between"  alignItems="center">
        <Flex>
                <Input type="text" placeholder="Find A PO" value={searchTerm} onChange={e => setSearchTerm(e.target.value)}/>
            </Flex>
            <Flex></Flex>
            <Flex display="flex" justifyContent="flex-end" >
                <div style={{marginRight:"20px",display:"flex",alignItems:"center"}}><LineStyleOutlinedIcon style={{fontSize:"2rem"}} onClick={() => {setSearchTerm("");setComp("")}}/></div>
                <div style={{marginRight:"20px",display:"flex",alignItems:"center"}}><MoreOutlinedIcon style={{fontSize:"2rem"}} onClick={() => {setSearchTerm("");setComp("addpo")}}/></div>
                <div style={{marginRight:"20px",display:"flex",alignItems:"center"}}><GroupAddOutlinedIcon onClick={() => setComp("add")} style={{fontSize:"2rem"}} /></div>
            </Flex>
        </Flexed>
    </Ribbon>
</Wrap>

<Wrap >

{comp === "" && searchTerm &&  <Posearch term={searchTerm} setComp={setComp} setCurrentSelectedPo={setCurrentSelectedPo} setSearchTerm={setSearchTerm}/>}
{comp === ""  && !searchTerm && <Dash comp={comp} setComp={setComp} setCurrentSelectedPo={setCurrentSelectedPo}/>}
{/* {comp === "add"  && !searchTerm && <AddVendor comp={comp} setComp={setComp} />} */}
{comp === "addpo"  && !searchTerm && <Addpo comp={comp} setComp={setComp} />}
{comp === "po"  && !searchTerm && <PO id={currentSelectedPo._id} comp={comp} setComp={setComp} currentSelectedPo={currentSelectedPo} errors={errors} errorset={errorset} />}
{/* {comp === "printpo"  && !searchTerm && <PrintComponent setComp={setComp}/>} */}
</Wrap>

  </>
  )
}

export default Purchaseorders