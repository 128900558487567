import { useEffect, useState, useContext } from 'react'
import Modal from '../../../components/Modal/Modal'
import Addvendor from '../../../components/vendors/Addvendor'
import styled from 'styled-components'
import {Flexed,Flex,Img,Input,Datepick,Label,currency,Textarea,Button,PhoneNumber} from '../../../Shared'
import PrintComponent from '../PrintComponent'
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { UserContext } from '../../../context/UserContext';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { v4 as uuidv4 } from 'uuid';
  import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
  import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
  import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';

  const Wrap = styled.div`
width:100%;
min-height:100vh;

th,td{

  text-align:left;
}
thead > tr:first-child{
border-bottom:1px solid var(--plsGrey)
}

thead tr{
  background-color:rgb(100,140,167,.3);
padding:3px 0px;
color:var(--plsGrey);
}
tbody tr{
padding:3px 0px;
}

.active{
  background-color:black;
}
.error{
  border:2px solid red;
}
`;

const Addpo = () => {
const {userInfo} = useContext(UserContext)


    const initialSelectedItems = {
        divide: 0,
        stockid:"",
      extprice: 0,
      id: "",
      item: "",
      itemname: "",
      jobnum: "",
      length: "",
      orderby: "",
      priceunit: "",
      quantity: 0,
      type: "",
      unitprice:0,
      weight:0,
      width: "",
      category:"",
      notes:"",
       stockid:0
      }
      const initialvendor = {
        address:{
          address1:"",
          address2:"",
          city:"",
          state:"",
          zip:""
        },
        company:{
          company_id:"",
          dba:"",
          name:""
        },
        numbers:{
          fax:"",
          main:"",
          other:""
        },
        type:"",
        website:"",
        _id:"",
        notes:""
      }
      


    const [errors, setErrors] = useState(false)
    const [tomorrow, setTomorrow] = useState({})
    const [selectedVendor, setSelectedVendor] = useState(initialvendor)
    const [vendorList, setVendorList] = useState([])
    const [vendorTypes, setVendorTypes] = useState([])
    const [items, setItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(initialSelectedItems)
    const [itemList, setItemList] = useState([])
    const [baseitem, setBaseitem] = useState("")
    const [total, setTotal] = useState(0)
    const [itemactive, setItemactive] = useState(false)
    const [recalc, setRecalc] = useState(false)
    const [addvendor, setAddvendor] = useState(false)
    const [tempvendor, setTempvendor] = useState("")
    const [addedVendor, setAddedVendor] = useState({})

    const tom = new Date()
    const tomor = tom.setTime(tom.getTime() +  (1 * 24 * 60 * 60 * 1000));
    const sam = new Date(tomor)
    const initialPO = {
        createdBy:userInfo.id,
            vendor: "",
            items:[],
         dueDate:sam.toISOString(),
         totalcost:0,
         status:0,
         id:null,
         totalcost:0
      }
      const [poinfo, setPoinfo] = useState(initialPO)
    
    const orderbylist= ["Sheets","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]
const priceunits= ["PER M","CWT","Sheet","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]

useEffect(() => {
    const getVendors = async() => {
       await axios.get("https://highgroundapi.herokuapp.com/vendor")
        .then(response => setVendorList(response.data))
        .catch(err => console.log(err))
        }
        const getVendorTypes = async() => {
            await axios.get("https://highgroundapi.herokuapp.com/list/vendorTypes")
             .then(response => setVendorTypes(response.data.vendor.items))
             .catch(err => console.log(err))
             }
        getVendors();
        getVendorTypes();
},[selectedVendor])

useEffect(() => {
      getItems();
  },[selectedItem.type])


  const getItems = async() => {
    setSelectedItem({...selectedItem, 
      divide: 0,
      stockid:0,
    extprice: 0,
    item: "",
    itemname: "",
    length: "",
    orderby: "",
    priceunit: "",
    quantity: 0,
    unitprice:0,
    weight: 0,
    width: "",
    category:"",
  })
  getListItems()
    }

    
  const getListItems = async() => {
   
    let g;

    switch(selectedItem.type.toLocaleLowerCase()){
      case "text":
      case "cover stock":
      case "board stock":
      case "roll stock":
      case "envelope":
      await axios.get(`https://highgroundapi.herokuapp.com/paper/group/${selectedItem.type.toLocaleLowerCase()}`)
       .then(response => {
        setItemList(response.data)
        setBaseitem("paper")
  console.log(response.data)
       })
       .catch(err => console.log(err))
        break;
        case "ink":
   await axios.get(`https://highgroundapi.herokuapp.com/ink`)
       .then(response => setItemList(response.data))
       .catch(err => console.log(err))
       setBaseitem("ink")
        break;
        default :
        g = {"item":selectedItem.type}
  await axios.post("https://highgroundapi.herokuapp.com/supplies",g)
  .then(response => {console.log(response.data);setItemList(response.data)})
         .catch(err => console.log(err))
         setBaseitem("supplies")
         break;
       }  
    }

console.log(itemList)
useEffect(() => {
    let totalt = 0;
  const get = () => {
    items.map(item => {
      totalt += parseFloat(item.extprice)
    })
    setTotal(totalt)
    setPoinfo({...poinfo,totalcost:totalt})
   
  }
 get()
},[recalc])


useEffect(() => {
    const info = {
        dueDate:poinfo.dueDate,
        //dueOrigDate:poinfo.dueOrigDate,
        poDate:poinfo.poDate,
       // poOrigDate:poinfo.poOrigDate,
        vendor:addedVendor
      }
        axios.post("https://highgroundapi.herokuapp.com/po/add",info)
        .then(res => {setPoinfo({...poinfo,ponum:res.data.po.ponum,id:res.data.po._id});toast.success(`PO ${res.data.po.ponum} has been created`)})
        .then(setSelectedVendor(addedVendor) )
        .catch(err => console.log(err))
},[addedVendor])


const setvendor = (e) => {
    console.log(e.target.value)
    const blah = vendorList?.filter(item => item.company.name?.toLowerCase() === e.target.value.toLowerCase())
     if(blah.length === 0){
       setSelectedVendor({...selectedVendor => [{...selectedVendor.company,name:e.target.value}]})

      // setChanges(true) 
     }else{
        setSelectedVendor(blah[0])
        setSelectedItem({...selectedItem,type:blah[0].type})
       //setReload(!reload)
      // setChanges(true)
      setItemactive(true)
     }
     setTempvendor(e.target.value)
     if(!poinfo.ponum && blah.length > 0){
   const info = {
          dueDate:poinfo.dueDate,
          //dueOrigDate:poinfo.dueOrigDate,
          poDate:poinfo.poDate,
         // poOrigDate:poinfo.poOrigDate,
          vendor:blah[0]._id
        }
          axios.post("https://highgroundapi.herokuapp.com/po/add",info)
          .then(res => {setPoinfo({...poinfo,ponum:res.data.po.ponum,id:res.data.po._id});toast.success(`PO ${res.data.po.ponum} has been created`)})
          .catch(err => console.log(err))
    
        }
        if(poinfo.ponum && blah.length > 0){
        const info = {
          id:poinfo.id,
          value:blah[0]._id,
          feild:"vendor"
          }
            axios.post("http://highgroundapi.herokuapp.com/po/updateitem",info)
            .then(res => {setPoinfo({...poinfo,vendor:blah[0]._id});toast.success('Vendor Updated')})
            .catch(err => console.log(err))
          }
        }
 
             const setitem = async(e) => {
            var inputValue = e.target.value;
           // setSelectedItem({...selectedItem, itemname: e.target.value})
            let id;
            let cat;
            let value;
                var options = document.getElementById("items").childNodes;
  
                for (var i = 0; i < options.length; i++) {
                  if (options[i].value === inputValue) {
                    id = (options[i].dataset.item);
                    cat = (options[i].dataset.cat);
                    value = (e.target.value);
          
         await axios.get(`https://highgroundapi.herokuapp.com/${cat}/${id}`)
            .then(response => {

            switch(cat){
            case "ink":
                console.log(response.data)
              setSelectedItem({...selectedItem,unitprice:response.data.price,orderby:response.data.defaultorderby,priceunit:response.data.defaultpriceunit,item:id,itemname:response.data.name,stockid:response.data.stockid})
              setBaseitem("ink")
              break;
              case "paper":
                console.log(response.data)
                setSelectedItem({...selectedItem,unitprice:response.data.price,orderby:response.data.defaultorderby,priceunit:response.data.defaultpriceunit,item:id,itemname:response.data.name,weight:parseInt(response.data.weight),divide:response.data.divide,stockid:response.data.id,category:response.data.category,stockid:response.data.stockid})
                setBaseitem("paper")
              break;
              default:
              setSelectedItem({...selectedItem,unitprice:response.data.price,orderby:response.data.defaultorderby,priceunit:response.data.defaultpriceunit,item:id,itemname:response.data.name,stockid:response.data.stockid})
              setBaseitem("supplies")
            }
            })
            .catch(err => console.log(err))
                    break;
                    
              
            }else{
                setSelectedItem({...selectedItem, 
                  divide: 0,
              stockid:"",
            extprice: 0,
            item: "",
            itemname: e.target.value,
            jobnum: "",
            length: "",
            orderby: "",
            priceunit: "",
            quantity: 0,
            unitprice:0,
            weight: 0,
            width: "",
            category:""})   
              }
             
            }

            }


            useEffect(() => {

                let neww;
                let length = parseFloat(selectedItem.length);
                let width = parseFloat(selectedItem.width);
                let weight = parseFloat(selectedItem.weight);
                let divide = parseFloat(selectedItem.divide);
                let a;
              
                  switch(selectedItem.priceunit){
                    case "PER M":
                      a = (selectedItem.quantity / 1000) * selectedItem.unitprice
                      setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})
                        break;
                        case "CWT":
               if(selectedItem.type === "Roll Stock"){
                a = (selectedItem.quantity / 100) * selectedItem.unitprice
                setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})
               }else{
                    a = length * width * weight * 2;
                    a /= divide;
                    a *= selectedItem.quantity
                    a /= 1000
                    a *= selectedItem.unitprice
                    a /= 100
                setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})
               }
                          break;
                        default:
                          a = selectedItem.quantity * selectedItem.unitprice;
                          setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})
                  }
                
                  
                },[selectedItem.quantity,selectedItem.unitprice,selectedItem.priceunit])

                const updateItem = (id,feild,value) => {
                    console.log(id,feild,value)
                    const updateinfo = {
                      id,
                      feild,
                      value
                    }
                        axios.post(`https://highgroundapi.herokuapp.com/${baseitem}/update`, updateinfo)
                        .then(response => {toast.success(`${selectedItem.itemname} - ${feild} has been updated`)})
                        .catch(err => console.log(err))
                  }


                  const saveitem = (e) => {
                    e.preventDefault()
              
                    update()
                  }

                  const update = async(e) => {
                
                    if(selectedItem.id){
                  const objIndex = items.findIndex((obj => obj.id === selectedItem.id));
                  items[objIndex] = selectedItem
                 setRecalc(!recalc)
                    toast.success(`${selectedItem.itemname}  has been updated`)
                    }else{
                      const newItem = {
                        divide: selectedItem.divide ,
                        extprice: selectedItem.extprice ,
                        id: uuidv4(),
                        item: selectedItem.item , 
                        itemname: selectedItem.itemname , 
                        jobnum: selectedItem.jobnum ,
                        length: selectedItem.length , 
                        orderby: selectedItem.orderby ,
                        priceunit: selectedItem.priceunit ,
                        quantity: selectedItem.quantity , 
                        type: selectedItem.type , 
                        unitprice: selectedItem.unitprice ,
                        weight: selectedItem.weight , 
                        width: selectedItem.width,
                        category:selectedItem.category,
                        notes: selectedItem.notes,   stockid:selectedItem.stockid 
                      }
                  setItems(items => [...items,newItem])
                  setItemactive(false)
                  toast.success(`${selectedItem.itemname}  has been added`)
                  setRecalc(!recalc)
                  //setPosavecheck(true)
                    }
                    setSelectedItem(initialSelectedItems)
                    setItemList([])
                    setItemactive(false)
                    setRecalc(!recalc)
                    setErrors(true)
                  }

                  
                  const savePO = () => {
const info = {
    id:poinfo.id,
    value:items,
    feild:"items"
    }
      axios.post("https://highgroundapi.herokuapp.com/po/updateitem",info)
      .then(res => {toast.success('PO Saved');
      const info = {
        id:poinfo.id,
        value:total,
        feild:"totalcost"
        }
          axios.post("https://highgroundapi.herokuapp.com/po/updateitem",info)
          .then(res =>  '')
          .catch(err => console.log(err))
    })
      .then(res => setErrors(false))
      .catch(err => toast.success('PO NOT Saved'))
                  }

                  const deleteItem = (value) => {
                    setItems(items.filter(item => item.id !== value))
                    setRecalc(!recalc)
                    setSelectedItem(initialSelectedItems)
                    setErrors(true)
                    setItemactive(false)
                  }

                  

const addtoinv = async() => {
  console.log(selectedItem)
  if(!selectedItem.itemname){
   return toast.error("Item must have a Item Name to be saved")
  }
let db;
let additem;
switch(selectedItem.type.toLowerCase()){
case "text":
case "board stock":
case "cover stock":
case "envelopes":
  case "roll stock":
   additem = {
    divide: 0,
    vendor:selectedVendor._id,
  itemname: selectedItem.itemname,
  orderby: selectedItem.orderby,
  priceunit: selectedItem.priceunit,
  unitprice:selectedItem.unitprice,
  weight:selectedItem.weight,
  category:selectedItem.type.toLowerCase()
  }
db='paper'
  break;
  case "ink":
    additem = {
    name:selectedItem.itemname ,
    vendor:selectedVendor._id,
    price:selectedItem.unitprice ,
defaultorderby:selectedItem.orderby ,
defaultpriceunit:selectedItem.priceunit,
category:selectedItem.type.toLowerCase()
  }
    db='ink'
  break;
 default:
  additem = {
    name:selectedItem.itemname,
    price:selectedItem.unitprice,
    vendor:selectedVendor._id,
    category:selectedItem.type.toLowerCase(),
    defaultorderby:selectedItem.orderby,
    size:selectedItem.length+" x "+selectedItem.width,
    defaultpriceunit:selectedItem.priceunit,
    notes:selectedItem.notes,
  }
    db='supplies'
  break;
}
const newaddeditem = await axios.post(`https://highgroundapi.herokuapp.com/${db}/add`,additem)
                                .then(res => setSelectedItem({...selectedItem,item:res.data._id,stockid:res.data.stockid}))
                                .then(res => toast.success(`${selectedItem.itemname} has been added to database`))
                          .then( () => {getListItems()})
                                .catch(err => {toast.error(err.response.data.message)})
}



//console.log(poinfo)
//console.log(errors)
  return (
    <Wrap >
<ToastContainer position="bottom-center" theme="dark"/>
<Modal show={addvendor} header={`Add  New Vendor`} onCancel={() => {setAddvendor(false)}} ><Addvendor onCancel={() => {setAddvendor(false)}} tempvendor={tempvendor} setAddedVendor={setAddedVendor} /></Modal>    
   <div style={{backgroundColor:"#EDEEF2",zIndex:"5",display:"flex",justifyContent:"flex-end",padding:"10px 0px",position:"sticky",top:"258px",width:"100%",textAlign:"right"}}>

   {errors && <Flexed position="absolute" justifyContent="center" width="100%"  fontSize="calc(1.4rem + .5vw)" fontWeight="bold" color="rgb(255,0,0,.6)"><Flex textAlign="center">PO NOT SAVED</Flex></Flexed>}
   {selectedVendor.company?.company_id > 0 ? <PrintComponent id={poinfo.ponum} to={selectedVendor} items={items} total={total} /> : ''}{errors && <Button style={{marginLeft:"20px",zIndex:"5"}} onClick={savePO} >Save PO</Button>}
   </div>
   <form style={{border:"1px solid #C3D0DB",width:"100%",position:"relative",padding:"10px 0px 20px 0px"}}>
  <Flexed gap="10px">
    <Flex textAlign="left" flex="2"><Label>Vendor</Label><br />{selectedVendor.company?.company_id  ? "" : <Button type="button" bgcolor="#7ABD42" color="white" padding="4px 15px" style={{position:"absolute",top:"-5px",right:"10px"}} onClick={ () => {setAddvendor(true)} }>Add Vendor & Create PO</Button>}
    
    <Input  type="text" list="vendors" onChange={setvendor} value={selectedVendor.company?.name}  required/>
<datalist id="vendors">
{
  vendorList?.map(vendor => (
    <option key={vendor._id} data-cid={vendor.company?.company_id} value={vendor.company?.name}/>
  ))
}
</datalist>

    </Flex>
    <Flex textAlign="left" flex=".5"><Label>PO#</Label><br /><Input type="text" placeholder="PO #" defaultValue={poinfo?.ponum} disabled={true} /></Flex>
    <Flex textAlign="left" flex="0"><Label>Order Date</Label><br /><Datepick selected={new Date()} disabled={true}/></Flex>
    <Flex textAlign="left" flex="0"><Label>Due Date</Label><br /><Datepick  selected={new Date(poinfo?.dueDate)} /></Flex>
  </Flexed>
  </form>
  <div className="" style={{width:"100%",overflowX:"scroll",border:"1px solid #C3D0DB",position:"relative"}}>

<table className="sortable hand" style={{width:"200%",borderCollapse:"collapse"}}>
<thead style={{backgroundColor:"#C3D0DB"}}>
<tr style={{display:"flex",justifyContent:"flex-start"}}>
<th style={{flex:".3",padding:"3px 0px"}}></th>
<th style={{flex:"1",padding:"3px 0px"}}>Job#</th>
<th style={{flex:"2",padding:"3px 0px"}}>Item</th>
<th style={{flex:"1",padding:"3px 0px"}}>Ordered</th>
<th style={{flex:"1",padding:"3px 0px"}}>Recieved</th>
<th style={{flex:"1.2",padding:"3px 0px"}}>Cost</th>
<th style={{flex:".5",padding:"3px 0px",textAlign:"center"}} >Total</th>
<th style={{flex:"1",padding:"3px 0px 3px 30px"}}>Date Ordered</th>
<th style={{flex:"1",padding:"3px 0px"}}>Due Date</th>
<th style={{flex:"1",padding:"3px 0px"}}>Received Date</th>
<th style={{flex:"1",padding:"3px 0px"}}>Type</th>
<th style={{flex:".5",padding:"3px 0px"}}>Size</th>
<th style={{flex:".5",padding:"3px 0px"}}>Invoiced</th>
<th style={{flex:"3",padding:"3px 0px"}}>Notes</th>
</tr>
</thead>
 <tbody style={{height:"150px",fontSize:"1rem"}} id="polistitems">
 {items?.map((item,index) => {
  return (
  <>
  <tr key={index} style={{display:"flex",borderBottom:"1px solid var(--plsGrey)"}}  onClick={() => {setSelectedItem(item);}}>
       <td style={{flex:".3",padding:"3px 0px 3px 5px",position:"relative"}}></td>
       <td onClick={() => {setItemactive(true)}} style={{flex:"1",padding:"3px 0px 3px 5px"}}>{item.jobnum}</td>
  <td onClick={() => {setItemactive(true)}} style={{flex:"2",padding:"3px 0px"}}>{item.itemname}</td>
  <td onClick={() => {setItemactive(true)}} style={{flex:"1",padding:"3px 0px"}}>{item.quantity}&nbsp;{item.orderby}</td>
  <td onClick={() => {setItemactive(true)}} style={{flex:"1",padding:"3px 0px"}}>{item?.recquantity}</td>
  <td onClick={() => {setItemactive(true)}} style={{flex:"1.2",padding:"3px 0px"}}>{currency(item.unitprice)} per {item.priceunit}</td>
  <td onClick={() => {setItemactive(true)}} style={{flex:".5",padding:"3px 0px",textAlign:"right"}}>{currency(item.extprice)}</td>
  <td onClick={() => {setItemactive(true)}} style={{flex:"1",padding:"3px 0px 3px 25px"}}>{new Date(poinfo?.createdAt).toLocaleDateString()}</td>
<td onClick={() => {setItemactive(true)}} style={{flex:"1",padding:"3px 0px"}}>{new Date(poinfo?.dueDate).toLocaleDateString()}</td>
<td onClick={() => {setItemactive(true)}} style={{flex:"1",padding:"3px 0px"}}>{item?.recDate && new Date(item.recDate).toLocaleDateString()}</td>
<td onClick={() => {setItemactive(true)}} style={{flex:"1",padding:"3px 0px"}}>{item.type}</td>
  <td onClick={() => {setItemactive(true)}} style={{flex:".5",padding:"3px 0px"}}>{item.length ? item.length+" x "+item.width : ""}{item.width && !item.length ? item.width : ""}</td>
  <td onClick={() => {setItemactive(true)}} style={{flex:".5",padding:"3px 0px"}}>{poinfo?.invoiceDate && new Date(poinfo?.invoiceDate).toLocaleDateString()}</td>
  <td onClick={() => {setItemactive(true)}} style={{flex:"3",padding:"3px 0px"}}>{item.notes}</td>
  </tr>  
  </>
)
})
}

 <tr style={{display:"flex"}}>

  <td style={{flex:"6.2",padding:"3px 0px",textAlign:"right"}}>Total:&nbsp;&nbsp;</td>
  <td style={{flex:".5",padding:"3px 0px",textAlign:"right"}}>{currency(total)}</td>
  <td style={{flex:"7.8",padding:"3px 0px"}}></td>
  </tr> 
</tbody>
<tfoot></tfoot></table>

</div>




<form style={{marginTop:"10px",border:`${itemactive ? "2px solid #7ABD42" : "1px solid #C3D0DB"}`,padding:"10px 10px"}} onSubmit = {saveitem}>
   <Flexed gap="10px" >

<Flex><Label>Item Type</Label><br />
  <Input type="text" list="vendorTypes"  value={selectedItem.type} onChange={e => setSelectedItem({...selectedItem,type:e.target.value})} placeholder="Select Item" required/>
<datalist id="vendorTypes">
{
vendorTypes?.map(vendor => (
<option key={vendor.value} value={vendor.value} />
))
}
</datalist></Flex>
<Flex flex="3"><Label>Items:&nbsp; {itemList.length}</Label><br />
{itemList.length > 0 ? <><Input type="text" list="items" id="listofitems"  onChange={setitem} value={selectedItem?.itemname} name="item" placeholder="Select Item" autoComplete="off" required/>
<datalist id="items">
{
 itemList?.map((item,index) => {
  switch(selectedItem.type.toLowerCase()){
   case "text":
    case "board stock":
    case "cover stock":
      case "roll stock":
      case "envelope":
return  <option key={index} data-item={item._id} data-cat="paper" value={item.name} />
        break;
        case "ink":
    return  <option key={index} data-item={item._id} data-cat="ink" value={item.name} />
            break;
        default :
        return  <option key={index} data-item={item._id} data-cat="supplies" value={item.name} />
  }
})
}
</datalist>
</> 
:
<><Input type="text"   onChange={setitem} value={selectedItem?.itemname} name="item" placeholder="Enter Item" required/></>}


</Flex>

       
<Flex flex=".5"><Label>ID<br /><Input type="text" placeholder="Stock ID" value={selectedItem.stockid} disabled={true} /></Label></Flex>
<Flex flex=".7"><Label>Job #</Label><br />
      <Input type="text"  onChange={e => setSelectedItem({...selectedItem,jobnum:e.target.value})} value={selectedItem.jobnum} placeholder="Job #" />
</Flex>
</Flexed>

<Flexed gap="10px" justifyContent="flex-start" margin="10px 0px 0px 0px"><Flex flex=".3"   textAlign="center">{selectedItem.type !== "Roll Stock" && <><Label>L</Label><br /><Input type="text" placeholder="Length" value={selectedItem.length} onChange={e => setSelectedItem({...selectedItem,length:e.target.value})} required={selectedItem.type === "Sheet Stock" ? true : false} /></>}</Flex>
<Flex flex=".3"  textAlign="center"><Label>W</Label><br /><Input type="text" value={selectedItem.width} onChange={e =>  setSelectedItem({...selectedItem,width:e.target.value})} placeholder="Width"  required={selectedItem.type === "Sheet Stock" || selectedItem.type === "Roll Stock" ? true : false}/></Flex>
<Flex flex=".3"   textAlign="center"><Label>Weight</Label><br /><Input type="text" placeholder="Weight" value={selectedItem.weight} onChange={e => setSelectedItem({...selectedItem,weight:parseInt(e.target.value)})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>
<Flex flex=".3"   textAlign="center"><Label>Weight Basis</Label><br /><Input type="text" placeholder="Weight Basis" value={selectedItem.divide} onChange={e => setSelectedItem({...selectedItem,divide:e.target.value})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>
<Flex flex="1"></Flex></Flexed>

 <Flexed gap="10px"padding="15px 0px">
 
    <Flex><Label>Quantity</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,quantity:parseInt(e.target.value)})} value={selectedItem?.quantity} placeholder="Quantity" /></Flex>
    <Flex flex=".7"><Label>Item Order By</Label><br />
<Input type="text" list="orderbylist" onChange={e => setSelectedItem({...selectedItem,orderby:e.target.value})} value={selectedItem.orderby} placeholder="Order By" required/>
<datalist id="orderbylist">
{
  orderbylist?.map((item,index) => (
    <option key={index} value={item} />
  ))
}
</datalist>
<Button type="button" padding="6px 13px" style={{position:"absolute",bottom:"-28px",right:"0",zIndex:"2"}} onClick={() => updateItem(selectedItem.item,"defaultorderby",selectedItem.orderby)} >Update </Button></Flex>
    <Flex ><Label>Unit Price</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,unitprice:parseFloat(e.target.value)})} value={selectedItem?.unitprice} placeholder="Unit Price" /><Button type="button"  onClick={() => updateItem(selectedItem.item,"price",selectedItem.price)} style={{position:"absolute",bottom:"-28px",right:"0"}}>Update Unit Price</Button></Flex>
    <Flex flex=".7"><Label>Price Unit</Label><br />
      <Input type="text" list="priceu" onChange={e => setSelectedItem({...selectedItem,priceunit:e.target.value})} value={selectedItem.priceunit} placeholder="Price Unit"  required/>
<datalist id="priceu">
{
  priceunits?.map((item,index) => (
    <option key={index} value={item} />
  ))
}
</datalist><Button type="button" style={{position:"absolute",bottom:"-28px",right:"0",zIndex:"3"}} onClick={() => updateItem(selectedItem.item,"defaultpriceunit",selectedItem.priceunit)}>Update Price Unit</Button></Flex>
    <Flex><Label>Ext Price</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,extprice:e.target.value})} value={selectedItem?.extprice} placeholder="Ext Price" /></Flex>
  </Flexed>
  <Flexed padding="5px 0px" alignItems="flex-end">
  <Flex flex=".6" ><Label>Item Notes</Label><br /><Textarea rows="5"  onChange={e => setSelectedItem({...selectedItem,notes:e.target.value})} value={selectedItem.notes} ></Textarea></Flex>
   
    <Flex justifyContent="flex-end" alignItems="center"display="flex"  padding="15px" width="100%">
        <div style={{marginRight:"20px"}}><Button padding="8px 20px" width="100%" bgcolor="var(--plsBlue)"  color="white" style={{marginRight:"25px"}}>{selectedItem.id === "" ?  'Add Item' : 'Update Item' }</Button></div><div style={{marginRight:"20px"}}>  <Button padding="8px 20px" width="100%" bgcolor="yellow"  color="var(--plsBlue)" style={{marginRight:"25px"}} type="button" onClick={() => {setSelectedItem(initialSelectedItems);setItemactive(false)}}>Clear Item</Button></div><DeleteForeverIcon style={{fontSize:"35px",color:"rgb(255,0,0,.6)",marginRight:"30px"}} onClick={e => {deleteItem(selectedItem.id)}}/>{!selectedItem.item && selectedItem.type && <BookmarkAddIcon style={{fontSize:"35px",color:"#7ABD42"}} onClick={addtoinv}/>}
    
    
    </Flex>
  </Flexed>
  </form>


    </Wrap>

)}

export default Addpo