import ColumnFilter  from './ColumnFilter'
import {Selectbox,currency} from '../../Shared'


export const supplyColumns = [
    {
        Header: 'Stockid',
        accessor: 'stockid',
        Filter: ColumnFilter,
        Flex:.5
    },
    {
        Header: 'Item Name',
        accessor: 'name',
        Filter: ColumnFilter,
        Flex:2
    },
    {
        Header: 'Category',
        accessor: 'category',
        Filter: ColumnFilter,
        Flex:1
    },
    {
        Header: 'Type',
        accessor: 'type',
        Filter: ColumnFilter,
        Flex:1,
        disableFilters:true
    },
    {
        Header: 'Cost',
        accessor: 'price',
        Filter: ColumnFilter,
        Flex:.8
    },
   
]

export const inkColumns = [
    {
        Header: 'Stockid',
        accessor: 'stockid',
        Filter: ColumnFilter,
        Flex:.5
    },
    {
        Header: 'Item Name',
        accessor: 'name',
        Filter: ColumnFilter,
        Flex:2
    },
    {
        Header: 'Cost',
        accessor: 'price',
        Filter: ColumnFilter,
        Flex:.8
    },
   
]

export const paperColumns = [

    {
        Header: 'Inv Id',
        accessor: 'invid',
        Filter: ColumnFilter,
        Flex:.4
    },
    {
        Header: 'Location',
        accessor: 'location',
        Filter: ColumnFilter,
        Flex:.8
    },
    {
        Header: 'Size',
        accessor: 'size',
        Filter: ColumnFilter,
        Flex:.7,
        Cell: props => (props.value !== undefined && props.value+'"')
    },
    {
        Header: 'Item Name',
        accessor: `paperid.name`,
        Filter: ColumnFilter,
        Flex:2,
   
    },
    {
        Header: 'Category',
        accessor: 'paperid.category',
        Filter: ColumnFilter,
        Flex:1
    },
  
    {
        Header: 'In Stock',
        accessor: 'currentInventory',
        Filter: ColumnFilter,
        Flex:.8,
    },
    
    {
        Header: 'Type',
        accessor: 'orderby',
        Filter: ColumnFilter,
        Flex:.6,
     
    },
    {
        Header: 'Cost',
        accessor: 'paperid.price',
        Filter: ColumnFilter,
        Flex:.8,
        Cell: props => (isNaN(props.value) ? '' : currency(props.value))
    },
    {
        Header: 'Total Value',
        accessor: 'cost',
        Filter: ColumnFilter,
        Flex:.8,
        Cell: props => currency(props.value)
    },
]
/*
 stockid:{type:Number},
        name: {type:String},
        type: {type:String},
        color: {type:String},
        vendor:{type:mongoose.Schema.Types.ObjectId, ref: 'Vendor'},
        price: {type:Number},
    specific: {type:Number},
    press: {type:String},
    pressname: {type:String},
    defaultorderby: {type:String},
    defaultpriceunit: {type:String},
*/
