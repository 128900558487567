import { Outlet} from 'react-router-dom'
import {Flexed,Flex,Img,Input,Datepick,Label,currency,Textarea,Button,PhoneNumber,Wrap} from '../../../Shared'

import { useState } from 'react';
import Modal from '../../../components/Modal/Modal'
import Addvendor from '../../../components/vendors/Addvendor'
import POnav from './POnav';

const Purchase = () => {
    const [addvendor, setAddvendor] = useState(false)
 
  return (
   <Wrap>

    <div style={{position:"sticky",top:"92px",width:"100%",backgroundColor:"#EDEEF2",zIndex:"3"}}>
    <Modal show={addvendor} header={`Add New Vendor`} onCancel={() => {setAddvendor(false)}} ><Addvendor onCancel={() => {setAddvendor(false)}} /></Modal>  
<Flexed padding="10px 0px">
    <Flex className="blue" fontSize="calc(1.5rem + .5vw)"> Purchase Orders</Flex>
</Flexed>
<POnav setAddvendor={setAddvendor} />
<Flexed gap="10px"> 
    <Flex><Input type="text" placeholder="Find PO" placement="top"/></Flex>
    <Flex><Input type="text" placeholder="Find Job#" /></Flex>
    <Flex><Input type="text" placeholder="Find Vendors" /></Flex>
    <Flex><Input type="text" placeholder="Find Items" /></Flex>
    {/*<Flex><Link to="new">Addpo</Link></Flex>*/}
</Flexed>

<hr style={{marginTop:"15px"}} />



</div>
<Outlet />

   </Wrap>
  )
}

export default Purchase