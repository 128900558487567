import {useState} from 'react'
import TableSet from '../../components/table/Tableset';
import { POHEADERS } from '../../components/table/Headers'
import { useEffect } from 'react';
import axios from 'axios';

const Posearch = ({term,setComp,setCurrentSelectedPo,setSearchTerm}) => {


  const [itemList, setItemList] = useState([])
  const [mainlist, setMainlist] = useState([])
console.log(term)

useEffect(() => {
  let list;
  const getpos = async() => {
   // list =  await axios.post('https://highgroundapi.herokuapp.com/po/search',term)
    list =  await axios.post('http://localhost:5003/po/search')
   .then(res => setItemList(res.data.po))
    .catch(err => console.log(err))
}
getpos()

},[])





  return (
    <>
    <div style={{borderTop:"1px solid #648ca7",marginTop:"25px"}}></div>
  {
    
  }
<div style={{borderTop:"1px solid #648ca7",marginTop:"25px"}}></div>
<TableSet headers={POHEADERS} data={itemList.filter(item => item.ponum.toString().includes(term))} setComp={setComp} setCurrentSelectedPo={setCurrentSelectedPo} setSearchTerm={setSearchTerm}/>
</>
  )
}

export default Posearch