import styled from 'styled-components'
import {Flexed,Flex,Wrap,Img,Input,Datepick,Label,Selectbox,Tooltip,Duplicate,Save,Print,Delete ,Edit,EditOff,Textarea,PhoneNumber,AddIcon} from '../../Shared'
import { useState,useContext,useEffect } from 'react'

import axios from 'axios';





export const Grid = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: ${props => props.cols || ""};

  
  grid-template-areas:${props => props.grid || ""};
  justify-content: start;


 .vendors{grid-area:vendors;position:relative;}
 .podate{grid-area:podate;position:relative;}
 .dba{grid-area:dba;position:relative;}
 .address1{grid-area:address1;position:relative;}
 .address2{grid-area:address2;position:relative;}
 .city{grid-area:city;position:relative }
 .state{grid-area:state;position:relative }
 .zip{grid-area:zip;position:relative }
 .type{grid-area:type;position:relative;}
 .phone{grid-area:phone;position:relative;align-self: top}
 .website{grid-area:website;position:relative;align-self: start}
 .notes{grid-area:notes;position:relative;min-height:100px;overflow:hidden;border:1px solid #c8c8c8;max-height:100%;}

`;
const EditVendor = ({onCancel,selectedVendor,setSelectedVendor}) => {

    const [vendorTypes, setVendorTypes] = useState([]);
    const [vendor, setVendor] = useState(selectedVendor)




    useEffect(() => {
            const getVendorTypes = async() => {
                await axios.get("https://highgroundapi.herokuapp.com/list/vendorTypes")
                 .then(response => setVendorTypes(response.data.vendor.items))
                 .catch(err => console.log(err))
                 }
            getVendorTypes();
    },[])
    

   
       


const addit = async() => {

  const savenewvendor = async() => {
    await axios.post("http://localhost:5003/vendor/update",vendor)
    .then(res => setSelectedVendor(vendor))
    .then(res => onCancel())
    .catch(err => console.log(err))
  }

  savenewvendor()
  
}

console.log(vendor)

  return (
    <>

    <Grid grid='"vendors vendors .... dba dba" "address1 address1 address2 address2 type" "city state zip notes notes" "phone website website notes notes"' cols='repeat(5, 1fr)'>
    <div className="vendors"><Label>Vendor:</Label><Input type="text" defaultValue={vendor.company?.name} onChange={e => setVendor({...vendor,company:{...vendor.company,name:e.target.value}})} /></div>
    
  
  </Grid>
<button onClick={addit}>here</button><button onClick={onCancel}>Cancel</button>
  </>
  )
}

export default EditVendor