import React, { useRef,useEffect ,useState} from "react";
import Moment from 'react-moment';


import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

import ReactToPrint from "react-to-print";
import { Flex, Flexed } from "../../../../Shared";


export default function PrintMovement({list,what,start,end}) {
  let componentRef = useRef();

 let starttime =  <Moment format="MM/DD/YYYY">
{start}
</Moment>
let endtime =  <Moment format="MM/DD/YYYY">
{start}
</Moment>
const [message, setMessage] = useState("")
  useEffect(() => {
const getpoinfo = async() => {

}

getpoinfo()
  })
 
 

  return (
    <>
      <div id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <LocalPrintshopOutlinedIcon style={{fontSize:"calc(1.5rem + .5vw)"}}/>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <ComponentToPrint list={list} what={what} start={starttime} end={endtime} ref={(el) => (componentRef = el)} />
        </div>
      </div>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {

    super(props);

  }

  render() {
    return (
      <div style={{border:"1px solid red",minHeight:"100vh",position:"relative",padding:"40px 40px 0px 20px",width:"100vw"}}>
       <div style={{width:"100%",textAlign:"center",fontSize:"calc(1.3rem + .5vw)"}}>Inventory Movement<br /><span style={{fontSize:"1rem",width:"100vw"}}>Items {this.props.what} between {this.props.start} & {this.props.end}</span> </div>

       <Flexed bgColor="rgb(0,0,0,.2)" padding="10px" margin="40px 0px 0px 0px">
        <Flex flex=".7" textAlign="left">Inv Id</Flex>
        <Flex  textAlign="left">Location</Flex>
        <Flex  textAlign="left">Size</Flex>
        <Flex flex="3" textAlign="left">Item</Flex>
        <Flex  textAlign="left">Category</Flex>
        <Flex  textAlign="left">In Stock</Flex>
        <Flex  textAlign="left">Type</Flex>
        <Flex flex=".7"  textAlign="left">Cost</Flex>
        <Flex flex=".7" textAlign="left">Value</Flex>
       </Flexed>
     
       {this.props.list.map((item) => (
        <Flexed borderBottom="1px solid black" padding="10px">
        <Flex  flex=".7" textAlign="left">{item.invid}</Flex>
        <Flex  textAlign="left">{item.location}</Flex>
        <Flex  textAlign="left">{item.size}</Flex>
        <Flex  flex="3" textAlign="left">{item.paperid.name}</Flex>
        <Flex  textAlign="left">{item.category}</Flex>
        <Flex  textAlign="left">{item.currentInventory}</Flex>
        <Flex  textAlign="left">{item.type}</Flex>
        <Flex flex=".7"  textAlign="left">{item.paperid.price}</Flex>
        <Flex flex=".7"  textAlign="left">{item.cost}</Flex>
       </Flexed>
       ))}
       
      </div>
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
