import styled from 'styled-components'
import 'react-tabs/style/react-tabs.css';
import {useState,useEffect} from 'react'
import { Grid} from '../po/PoStyles'
import {Flexed,Flex,Input,currency,Textarea,Button,PhoneNumber,Label,Datepick} from '../../Shared'
import Modal from '../../components/Modal/Modal';
import EditVendor from '../../components/vendors/EditVendor';
import axios from 'axios'
import { ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { v4 as uuidv4 } from 'uuid';

const Wrap = styled.div`
width:100%;
min-height:100vh;

th,td{

  text-align:left;
}
thead > tr:first-child{
border-bottom:1px solid var(--plsGrey)
}

thead tr{
  background-color:rgb(100,140,167,.3);
padding:3px 0px;
color:var(--plsGrey);
}
tbody tr{
padding:3px 0px;
}

.active{
  background-color:black;
}
.error{
  border:2px solid red;
}
`;



const POshow = (id) => {
  const initialSelectedItems = {
    divide: 0,
    stockid:"",
  extprice: 0,
  id: "",
  item: "",
  itemname: "",
  jobnum: "",
  length: 0,
  orderby: "",
  priceunit: "",
  quantity: 0,
  type: "",
  unitprice:0,
  weight:0,
  width:0,
  notes:""

  }
  const [poinfo, setPoinfo] = useState({})
  const [posaved, setPosaved] = useState(true)
  const [editvendor, setEditvendor] = useState(false)
  const [vendorTypes, setVendorTypes] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [selectedvendor, setSelectedVendor] = useState([]);
  const [reload, setReload] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(initialSelectedItems);
  const [itemList, setItemList] = useState([])
  const [baseitem, setBaseitem] = useState("")
  const [receive, setReceive] = useState(null)
  const [itemset, setItemset] = useState([])
  const orderbylist= ["Sheets","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]
  const priceunits= ["PER M","CWT","Sheet","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]



  useEffect(() => {
        const getVendorTypes = async() => {
            await axios.get("https://highgroundapi.herokuapp.com/list/vendorTypes")
             .then(response => setVendorTypes(response.data.vendor.items))
             .catch(err => console.log(err))
             }
        getVendorTypes();
  },[])

  useEffect(() => {
    const getVendors = async() => {
      await axios.get("https://highgroundapi.herokuapp.com/vendor")
       .then(response => setVendorList(response.data))
       .catch(err => console.log(err))
       }
    const getpo = async() => {
      await axios.post('http://localhost:5003/po/getpo',id)
      .then(response => {
      setPoinfo(response.data)
      setSelectedVendor(response.data.vendor)
      setItems(response.data.items)
      })
      .catch(err => console.log(err))
      }
      getVendors();
      getpo()
   
  },[reload])

  const changevendor = (e) => {
    var inputValue = e.target.value.toLowerCase();
    const vendor = vendorList?.filter(item => item.company.name?.toLowerCase() === inputValue)
if(vendor.length === 0){
  setSelectedVendor({})
  setPosaved(false)
}else{
if(vendor[0].company.company_id !== poinfo.vendor.company.company_id){
  
  setSelectedVendor(vendor[0])
  setPosaved(false)
}else{
  setSelectedVendor(vendor[0])
  setPosaved(true)
}
}}
 
const savenewvendor = () => {
  if(poinfo.ponum && selectedvendor.company?.company_id > 0){
    const info = {
    id:poinfo._id,
    value:selectedvendor._id,
    feild:"vendor"
    }
      axios.post("http://localhost:5003/po/updateitem",info)
      .then(res => setReload(!reload))
      .then(res => setPosaved(true))
      .catch(err => console.log(err))
    }else{
      toast.warning(`No vendor selected`)
    }}


    const updateItem = (id,feild,value) => {
      const updateinfo = {
        id,
        feild,
        value
      }
          axios.post(`https://highgroundapi.herokuapp.com/${baseitem}/update`, updateinfo)
          .then(response => {toast.success(`${selectedItem.itemname} - ${feild} has been updated`)})
          .catch(err => console.log(err))
    }
     
   
const deleteItem = (value) => {
 // setItems(items.filter(item => item.id !== value))
  //setChanges(true)
}


useEffect(() => {
  const getItems = async() => {
    


    let list;
  switch(selectedItem.type){
    case "Sheet Stock":
    case "Roll Stock":
    case "Envelope":
      list = await axios.get(`https://highgroundapi.herokuapp.com/paper/group/${selectedItem.type}`)
     .then(response => {
      setItemList(response.data)
      //setPapersize(true)
     })
     .catch(err => console.log(err))
      break;
      case "Ink":
      list = await axios.get(`https://highgroundapi.herokuapp.com/ink`)
     .then(response => setItemList(response.data))
     .catch(err => console.log(err))
      break;
      default :
      setItemList([])
      //setPrepoitem({...prepoitem,item:""})
     // setPapersize(false)
      }
    
    }
    getItems();
},[selectedItem.type])

const changeType = async(e) => {
  
 
 
  const getItems = async() => {
    let list;
  switch(selectedItem.type){
    case "Sheet Stock":
    case "Roll Stock":
    case "Envelope":
      list = await axios.get(`https://highgroundapi.herokuapp.com/paper/group/${selectedItem.type}`)
     .then(response => {
      setItemList(response.data)
      //setPapersize(true)
     })
     .catch(err => console.log(err))
      break;
      case "Ink":
      list = await axios.get(`https://highgroundapi.herokuapp.com/ink`)
     .then(response => setItemList(response.data))
     .catch(err => console.log(err))
      break;
      default :
      setItemList([])
      //setPrepoitem({...prepoitem,item:""})
     // setPapersize(false)
      }
    }
    getItems();
}
    
const setItem = async(e) => {
  var inputValue = e.target.value;
  if(selectedItem.type === "Building Maintanence" || selectedItem.type === "Shipping" || selectedItem.type === "Outside Service"){
    var inputValue = e.target.value;
    setSelectedItem({...selectedItem,itemname:inputValue})
    }else{
  let id;
  let cat;
  let value;
   
      var options = document.getElementById("items").childNodes;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value === inputValue) {
          id = (options[i].dataset.item);
          cat = (options[i].dataset.cat);
          value = (e.target.value);
        //  console.log(value)

   await axios.get(`https://highgroundapi.herokuapp.com/${cat}/${id}`)
  .then(response => {
  //console.log(response.data)


  
  switch(cat){
  case "ink":
    setSelectedItem({...selectedItem,unitprice:response.data.price,orderby:response.data.defaultorderby,priceunit:response.data.defaultpriceunit,item:id,itemname:response.data.pressname+" "+response.data.type+" "+response.data.color,notes:response.data.notes})
    setBaseitem("ink")
    break;
    case "paper":
      setSelectedItem({...selectedItem,unitprice:response.data.price,orderby:response.data.defaultorderby,priceunit:response.data.defaultpriceunit,item:id,itemname:response.data.name,weight:parseInt(response.data.weight),divide:response.data.divide,stockid:response.data.id,notes:response.data.notes})
      setBaseitem("paper")
    break;
    default:
      setSelectedItem({...selectedItem,unitprice:0,orderby:response.data.defaultorderby,priceunit:response.data.defaultpriceunit,item:id,itemname:response.data.pressname+" "+response.data.type+" "+response.data.color,notes:response.data.notes})
  }
   
  })
  .catch(err => console.log(err))
          break;
    }else{
      setSelectedItem({...selectedItem, 
        divide: 0,
    stockid:"",
  extprice: 0,
  item: "",
  itemname: e.target.value,
  length: 0,
  orderby: "",
  priceunit:"",
  quantity: 0,
  type: selectedItem.type,
  unitprice:0,
  weight: 0,
  width: 0,
notes:""
})
}}}}


const update = async(e) => {
  e.preventDefault()

  if(selectedItem.id){
const objIndex = items.findIndex((obj => obj.id === selectedItem.id));
items[objIndex] = selectedItem
setSelectedItem(initialSelectedItems)
//toast.success(`${selectedItem.itemname}  has been updated`)
//setChanges(true)
//setItemList([])
  }else{
    const newItem = {
      divide: selectedItem.divide ,
      extprice: selectedItem.extprice ,
      id: uuidv4(),
      item: selectedItem.item , 
      itemname: selectedItem.itemname , 
      jobnum: selectedItem.jobnum ,
      length: selectedItem.length , 
      orderby: selectedItem.orderby ,
      priceunit: selectedItem.priceunit ,
      quantity: selectedItem.quantity , 
      type: selectedItem.type , 
      unitprice: selectedItem.unitprice ,
      weight: selectedItem.weight , 
      width: selectedItem.width,
      notes:selectedItem.notes
    }
setItems(items => [...items,newItem])
//toast.success(`${selectedItem.itemname}  has been added`)
//setChanges(true)
//setSelectedItem(initialSelectedItems)
//setItemList([])
  }
}



console.log(selectedItem)

  return (
    <>
    <ToastContainer position="bottom-center" theme="dark" autoClose={7000}/>
<Modal show={editvendor} header={`Edit ${poinfo.vendor?.company?.name} Information`} onCancel={() => {setEditvendor(false)}} ><EditVendor reload={reload} setReload={setReload}  selectedvendor={selectedvendor} onCancel={() => {setEditvendor(false)}}  /></Modal>


<Wrap>
  {!posaved &&
  <Flexed style={{padding:"15px 0px",position:"sticky",top:"217px",width:"100%",backgroundColor:"#EDEEF2",zIndex:"1"}}>
      <Flex textAlign="left"></Flex>
    <Flex style={{marginTop:"-10px",fontWeight:"bold",fontSize:"calc(1.3rem + .5vw)",textAlign:"center",width:"100%",backgroundColor:"#EDEEF2",color:"rgb(255,0,0,.7)"}}>PO NOT SAVED</Flex> 
   <Flex display="grid" justifyContent="flex-end" alignItems="center">
  <div flex="fit-content" justifyContent="flex-end"  display="flex" >{selectedvendor.company?.company_id && <Button style={{marginLeft:"20px",backgroundColor:"rgb(122,189,66,.5)"}} >Save PO</Button>}</div>

   </Flex>
    </Flexed>
}
    <Flexed style={{marginTop:"15px"}}>
  <Flex textAlign="left">PO# <span style={{fontSize:"calc(1.2rem + .5vw)"}}>{poinfo?.ponum}</span></Flex>
  <Flex><p><span style={{color:"black",fontWeight:"bold",fontSize:"calc(.7rem + .5vw)"}}>{poinfo.vendor?.company.name} </span></p></Flex>
  <Flex textAlign="right"><p>Date Ordered : <span style={{color:"black",fontWeight:"bold"}}>{new Date(poinfo?.createdAt).toLocaleDateString()} </span></p></Flex>
  </Flexed>
  <Flexed borderBottom="1px solid grey">
  <Flex textAlign="left"><p>Items Count : <span style={{color:"black",fontWeight:"bold"}}>{poinfo.items?.length} </span></p></Flex>
  <Flex textAlign="center"></Flex>
  <Flex textAlign="right"><p>Delivery Scheduled : <span style={{color:"black",fontWeight:"bold"}}>{new Date(poinfo?.dueDate).toLocaleDateString()} </span></p></Flex>
  </Flexed>

  <div className="" style={{width:"100%",overflowX:"scroll",border:"1px solid var(--plsGrey)"}}>

<table className="sortable hand" style={{width:"200%",borderCollapse:"collapse"}}>
<thead>
<tr style={{display:"flex",justifyContent:"flex-start"}}>

<th style={{flex:".3",padding:"3px 0px"}}></th>
<th style={{flex:"1",padding:"3px 0px"}}>Job#</th>
<th style={{flex:"2",padding:"3px 0px"}}>Item</th>

<th style={{flex:"1",padding:"3px 0px"}}>Ordered</th>
<th style={{flex:"1",padding:"3px 0px"}}>Recieved</th>
<th style={{flex:"1",padding:"3px 0px"}}>Cost</th>
<th style={{flex:".5",padding:"3px 0px",textAlign:"center"}} >Total</th>
<th style={{flex:"1",padding:"3px 0px 3px 30px"}}>Date Ordered</th>
<th style={{flex:"1",padding:"3px 0px"}}>Due Date</th>
<th style={{flex:"1",padding:"3px 0px"}}>Received Date</th>
<th style={{flex:".5",padding:"3px 0px"}}>Size</th>
<th style={{flex:".5",padding:"3px 0px"}}>Invoiced</th>
<th style={{flex:"3",padding:"3px 0px"}}>Notes</th>




</tr>
</thead>
 <tbody style={{height:"150px",fontSize:"1rem"}} id="polistitems">

 {


 poinfo.items?.map((item,index) => {
  return (
  <>
  
  <tr key={index} onClick={() => {setSelectedItem(item)}}  style={{display:"flex",borderBottom:"1px solid var(--plsGrey)",backgroundColor:`${item.status > 0 ? "yellow" : ""}`,color:`${item.status > 1 ? "red" : ""}` }}  >
       <td style={{flex:".3",padding:"3px 0px 3px 5px",position:"relative"}}><ConfirmationNumberIcon style={{zIndex:"1",position:"absolute",left:"0",top:"1px"}} /></td>
       <td style={{flex:"1",padding:"3px 0px 3px 5px"}}>{item.jobnum}</td>
  <td style={{flex:"2",padding:"3px 0px"}}>{item.itemname}</td>

  <td style={{flex:"1",padding:"3px 0px"}}>{item.quantity}&nbsp;{item.orderby}</td>
  <td style={{flex:"1",padding:"3px 0px"}}>{item.recquantity}</td>
  <td style={{flex:"1",padding:"3px 0px"}}>{currency(item.unitprice)} per {item.priceunit}</td>
  <td style={{flex:".5",padding:"3px 0px",textAlign:"right"}}>{currency(item.extprice)}</td>
  <td style={{flex:"1",padding:"3px 0px 3px 25px"}}>{new Date(poinfo?.createdAt).toLocaleDateString()}</td>
<td style={{flex:"1",padding:"3px 0px"}}>{new Date(poinfo?.dueDate).toLocaleDateString()}</td>
<td style={{flex:"1",padding:"3px 0px"}}>{item?.recDate && new Date(item.recDate).toLocaleDateString()}</td>
  <td style={{flex:".5",padding:"3px 0px"}}>{item.length ? item.length+" x "+item.width : ""}</td>
  <td style={{flex:".5",padding:"3px 0px"}}>{poinfo?.invoiceDate && new Date(poinfo?.invoiceDate).toLocaleDateString()}</td>
  <td style={{flex:"3",padding:"3px 0px"}}>{item.recnotes}</td>
  </tr>  
  
  </>
)
})

}

 <tr style={{display:"flex"}}>

  <td style={{flex:"6.2",padding:"3px 0px",textAlign:"right"}}>Total:</td>
  <td style={{flex:".5",padding:"3px 0px",textAlign:"right"}}>{}</td>
  <td style={{flex:"7",padding:"3px 0px"}}></td>
  </tr>  
</tbody>
<tfoot></tfoot></table>
</div>
{/*////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
<form style={{position:"relative",border:"1px solid #648ca7",padding:"20px 5px"}} onSubmit={update} >
<Flexed gap="10px">
<Flex flex="1"><Label>Item Type</Label><br />
  <Input type="text" list="vendorTypes"  placeholder="Select Item" onChange={changeType} value={selectedItem?.type} required />
<datalist id="vendorTypes" >
{
vendorTypes?.map(vendor => (
<option key={vendor.value} value={vendor.value} />
))
}
</datalist></Flex>
<Flex flex="3"><Label>Items:&nbsp; {itemList.length}</Label><br />
{itemList.length > 0 ? <><Input type="text" list="items" id="listofitems" onChange={setItem} value={selectedItem?.itemname} name="item" placeholder="Select Item" required/>
<datalist id="items">
{
 itemList?.map((item,index) => {
  switch(selectedItem.type){
    case "Sheet Stock":
      case "Roll Stock":
      case "Envelope":
return  <option key={index} data-item={item._id} data-cat="paper" value={item.name} />
        break;
        case "Ink":
    return  <option key={index} data-item={item._id} data-cat="ink" value={item.pressname+" "+item.type+" "+item.color} />
            break;
        default :
  }
})
}
</datalist>
</> 
:
<><Input type="text"  value={selectedItem?.itemname} name="item" placeholder="Enter Item" required/></>}


</Flex>

       
<Flex flex=".5"><Label>ID<br /><Input type="text" placeholder="Stock ID" value={selectedItem.stockid} readOnly/></Label></Flex>
<Flex flex=".7"><Label>Item Order By</Label><br />
<Input type="text" list="orderbylist" onChange={e => setSelectedItem({...selectedItem,orderby:e.target.value})} value={selectedItem.orderby} placeholder="Order By" required/>
<datalist id="orderbylist">
{
  orderbylist?.map((item,index) => (
    <option key={index} value={item} />
  ))
}
</datalist>
<Button type="button" style={{position:"absolute",bottom:"-25px",right:"0"}} onClick={() => updateItem(selectedItem.item,"defaultorderby",selectedItem.orderby)}>Update</Button></Flex>
</Flexed>

{selectedItem.type === "Sheet Stock"  ?  <Flexed  gap="10px" justifyContent="flex-start" margin="20px 0px 0px 0px"><Flex flex=".3"   textAlign="center"><Label>L</Label><br /><Input type="text" placeholder="Length" value={selectedItem.length} onChange={e => setSelectedItem({...selectedItem,length:e.target.value})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>
<Flex flex=".3"  textAlign="center"><Label>W</Label><br /><Input type="text" value={selectedItem.width} onChange={e =>  setSelectedItem({...selectedItem,width:e.target.value})} placeholder="Width"  /></Flex>
<Flex flex=".3"   textAlign="center"><Label>Weight</Label><br /><Input type="text" placeholder="Weight" value={selectedItem.weight} onChange={e => setSelectedItem({...selectedItem,weight:parseInt(e.target.value)})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>
<Flex flex=".3"   textAlign="center"><Label>Weight Basis</Label><br /><Input type="text" placeholder="Weight Basis" value={selectedItem.divide} onChange={e => setSelectedItem({...selectedItem,divide:e.target.value})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>
<Flex flex="1"></Flex></Flexed> : ''}

 <Flexed gap="10px" padding="15px 0px">
  <Flex flex=".7"><Label>Job #</Label><br />
      <Input type="text"  onChange={e => setItems({...selectedItem,jobnum:e.target.value})} value={selectedItem.jobnum} placeholder="Job #" />
</Flex>
    <Flex><Label>Quantity</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,quantity:parseInt(e.target.value)})} value={selectedItem?.quantity} placeholder="Quantity" /></Flex>
    <Flex ><Label>Unit Price</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,unitprice:parseFloat(e.target.value)})} value={selectedItem?.unitprice} placeholder="Unit Price" /><Button type="button" onClick={e => updateItem(selectedItem.item,"price",selectedItem.unitprice)}  style={{position:"absolute",bottom:"-25px",right:"0"}}>Update </Button></Flex>
    <Flex flex=".7"><Label>Price Unit</Label><br />
      <Input type="text" list="priceu" onChange={e => setSelectedItem({...selectedItem,priceunit:e.target.value})} value={selectedItem.priceunit} placeholder="Price Unit"  required/>
<datalist id="priceu">
{
  priceunits?.map((item,index) => (
    <option key={index} value={item} />
  ))
}
</datalist><Button type="button" onClick={e => updateItem(selectedItem.item,"defaultpriceunit",selectedItem.priceunit)} style={{position:"absolute",bottom:"-25px",right:"0"}}>Update</Button></Flex>
    <Flex><Label>Ext Price</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,extprice:e.target.value})} value={selectedItem?.extprice} placeholder="Ext Price" /></Flex>
  </Flexed>
  <Flexed  gap="10px" alignItems="flex-end">
    <Flex flex="1"><Label>Item Notes</Label><br /><Textarea rows="3"  onChange={e => setSelectedItem({...selectedItem,notes:e.target.value})} value={selectedItem.notes} ></Textarea></Flex>
 <Flex></Flex>
    <Flex flex="1" display="flex"> <Button style={{marginRight:"25px"}}>{selectedItem.id === "" ?  'Add Item' : 'Update Item' }</Button>  <Button style={{marginRight:"50px"}} type="button" onClick={() => setSelectedItem(initialSelectedItems)}>Clear Item</Button>
  {selectedItem.id && <DeleteForeverIcon style={{fontSize:"35px",color:"rgb(255,0,0,.6)"}}onClick={e => {deleteItem(selectedItem.id);setSelectedItem([])}} />}</Flex>
  </Flexed>

</form>

{/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
<form style={{marginTop:"25px"}} >

<Grid grid='"edit ponum duedate podate notes notes" 
            "vendor vendor  vendorid type notes notes" 
            "address1 address1 address2 address2 notes notes" 
            "city state zip space notes notes" 
            "website website phone fax notes notes" 
            '
            cols='repeat(6, 1fr)'>
<div className="edit" ><Button type="button" onClick={() => setEditvendor(true)}>Edit Vendor</Button></div>
<div className="edit" >{poinfo.vendor?.company.company_id !== selectedvendor.company?.company_id  ? <Button type="button" color="white" bgcolor="rgb(255,0,0,.5)" onClick={() => savenewvendor()}>Change Vendor</Button>: ""}</div>
<div className="vendor">Vendor: <br />
<Input  type="text" list="vendors"  value={selectedvendor.company?.name}  onChange={changevendor} required/>
<datalist id="vendors">
{
  vendorList?.map(vendor => (
    <option key={vendor._id} data-cid={vendor.company.company_id} value={vendor.company.name}/>
  ))
}
</datalist>
</div>
<div className="vendorid">Vendor ID: <br /><Input type="text"  defaultValue={selectedvendor.company?.company_id}  disabled={true}/></div>
<div className="type">Type <br />
<Input type="text" list="vendorTypes" defaultValue={selectedvendor?.type} placeholder="Select Type" required disabled={true}/>
<datalist id="vendorTypes">
{
  vendorTypes?.map(vendor => (
    <option key={vendor.value} value={vendor.value} />
  ))
}
</datalist></div>
<div className="space"></div>

<div className="address1">Address 1: <Input type="text"  defaultValue={selectedvendor.address?.address1} disabled={true}/></div>
<div className="address2">Address 2: <Input type="text" defaultValue={selectedvendor.address?.address2} disabled={true}/></div>
<div className="city">City: <Input type="text" defaultValue={selectedvendor.address?.city} disabled={true}/></div>
<div className="state">State: <Input type="text" defaultValue={selectedvendor.address?.state} disabled={true}/></div>
<div className="zip">Zip: <Input type="text" defaultValue={selectedvendor.address?.zip} disabled={true}/></div>
<div className="website">Website: <Input type="text" defaultValue={selectedvendor.website} disabled={true} /></div>
<div className="phone">Phone: <Input type="text" defaultValue={PhoneNumber(selectedvendor.numbers?.main)} disabled={true}/></div>
<div className="fax">Fax: <Input type="text" defaultValue={PhoneNumber(selectedvendor.numbers?.fax)} disabled={true}/></div>

<div className="notes">Notes:<Textarea rows="5"  onChange={() => alert()} defaultValue={ selectedvendor.notes }  disabled={true} /></div>
</Grid>


</form>






  <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
  </Wrap>
  </>
  )
}

export default POshow