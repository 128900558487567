import Table from "../../../../components/table/Table"
import { paperColumns } from '../../../../components/table/Columns'
import { useMemo,useState,useEffect } from 'react'
import axios from 'axios'
import {Flexed,Flex,Img,Input,Datepick,Label,currency,Textarea,Button,PhoneNumber,Selectbox} from '../../../../Shared'
import POnav from "./../../../po/newpo/POnav"


const Inv = () => {

    const [DATA, setDATA] = useState([])
    const [list, setList] = useState('')

    useEffect(() => {
    let getdatastuff;
        let r;
        switch(list){
            case 'no':
               getdatastuff = async() => {
                     const r ={"coated":"no"}
                       await axios.post('http://localhost:5003/inv/allspecific',r)
                       .then(res => {console.log(res.data);setDATA(res.data)})
                       .catch(err => console.log(err)) 
                           }
                      
                           break;
                           case 'yes':
                             getdatastuff = async() => {
                                 const t ={"coated":"yes"}
                                   await axios.post('http://localhost:5003/inv/allspecific',t)
                                   .then(res => {console.log(res.data);setDATA(res.data)})
                                   .catch(err => console.log(err)) 
                                       }
                                      
                                       break;
                        default:
                             getdatastuff = async() => {
                                await axios.get('http://localhost:5003/inv/items')
                                .then(res => {setDATA(res.data)})
                                .catch(err => console.log(err)) 
                                    }
                                  
                                    break;
                    }
                   
                    getdatastuff()
                   
    },[list])   


const re = () => {

}
  return (
    <div style={{position:"relative"}}>
          <div style={{position:"sticky",top:"92px",backgroundColor:"#EDEEF2",zIndex:"3"}}>
          <Flexed>

<Flex fontSize="calc(1.3rem + .5vw)" padding="20px 0px">Paper Inventory</Flex>
</Flexed>

<POnav />

          </div>
       Total Items Found:&nbsp;{DATA.length}
            <Table supplyColumns={paperColumns} DATA={DATA} setList={setList} second='paper'/> </div>
  )
}

export default Inv