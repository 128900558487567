export const POHEADERS = [
    {
        "text":"PO#",
        "flex":1
    },
    {
        "text":"Vendor",
        "flex":1
    },
    {
        "text":"Due Date",
        "flex":1
    },
    {
        "text":"Ordered",
        "flex":1
    },
    {
       "text":"Items",
        "flex":2
    },
 
  ]