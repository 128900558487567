import styled from 'styled-components'
import 'react-tabs/style/react-tabs.css';
import {useContext,useState,useEffect,useCallback} from 'react'
import { Grid,ItemListArea, Items } from './PoStyles'
import {Flexed,Flex,Button,Input,Datepick,Label,Textarea,currency} from '../../Shared'
import {UserContext} from '../../context/UserContext';
import Modal from '../../components/Modal/Modal';
import EditVendor from '../../components/vendors/EditVendor';
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import PrintComponent from './PrintComponent';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';



const Wrap = styled.div`
width:100%;


th,td{

  text-align:left;
}
thead > tr:first-child{
border-bottom:1px solid var(--plsGrey)
}

thead tr{
  background-color:rgb(100,140,167,.3);
padding:3px 0px;
color:var(--plsGrey);
}
tbody tr{
padding:3px 0px;
}
`;






const Addpo = ({errorset,errors}) => {

const [poCreated, setPoCreated] = useState(false)
const [posavecheck, setPosavecheck] = useState(false)
const [total, setTotal] = useState(0)
const [te, setTe] = useState("")
const NEWDATE = new Date()
const tom = new Date()
const tomor = tom.setTime(tom.getTime() +  (1 * 24 * 60 * 60 * 1000));
const sam = new Date(tomor)
  const initialPO = {
    createdBy:"",
        vendor: "",
        items:[],
     dueDate:sam.toISOString(),
     dueOrigDate:sam,
     poDate:NEWDATE.toISOString(),
     poOrigDate:NEWDATE,
     totalcost:0,
     status:0,
     id:null
  }
const [newpoinfo, setNewpoinfo] = useState(initialPO);
const [items, setItems] = useState([]);
const initialSelectedItems = {
    divide: 0,
    stockid:"",
  extprice: 0,
  id: "",
  item: "",
  itemname: "",
  jobnum: "",
  length: "",
  orderby: "",
  priceunit: "",
  quantity: 0,
  type: "",
  unitprice:0,
  weight:0,
  width: "",
  category:"",
  notes:""
  }
  const initialvendor = {
    address:{
      address1:"",
      address2:"",
      city:"",
      state:"",
      zip:""
    },
    company:{
      company_id:"",
      dba:"",
      name:""
    },
    numbers:{
      fax:"",
      main:"",
      other:""
    },
    type:"",
    website:"",
    _id:"",
    notes:""
  }
const [selectedItem, setSelectedItem] = useState(initialSelectedItems);
const [vendorTypes, setVendorTypes] = useState([]);
const [vendorList, setVendorList] = useState([]);
const [newselectedVendor, setNewselectedVendor] = useState(initialvendor);
const {userInfo} = useContext(UserContext)
const [editvendor, setEditvendor] = useState(false)
const [good, setGood] = useState(true)
const [changes, setChanges] = useState(false)
const [updateitem, setUpdateitem] = useState(false)
const [itemList, setItemList] = useState([])
const [baseitem, setBaseitem] = useState("")


const orderbylist= ["Sheets","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]
const priceunits= ["PER M","CWT","Sheet","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]

useEffect(() => {

    const getVendors = async() => {
       await axios.get("https://highgroundapi.herokuapp.com/vendor")
        .then(response => setVendorList(response.data))
        .catch(err => console.log(err))
        }
        const getVendorTypes = async() => {
            await axios.get("https://highgroundapi.herokuapp.com/list/vendorTypes")
             .then(response => setVendorTypes(response.data.vendor.items))
             .catch(err => console.log(err))
             }

        getVendors();
        getVendorTypes();
   
},[newselectedVendor])



let totalt = 0;
useEffect(() => {
  const get = () => {
    items.map(item => {
      totalt += parseFloat(item.extprice)
    })
    setTotal(totalt)
  }
 get()
},[items])


useEffect(() => {
  const getItems = async() => {

    setSelectedItem({...selectedItem, 
      divide: 0,
      stockid:"",
    extprice: 0,
    item: "",
    itemname: "",
    jobnum: "",
    length: "",
    orderby: "",
    priceunit: "",
    quantity: 0,
    unitprice:0,
    weight: 0,
    width: "",
    category:"",
  notes:""})

    let list;
  switch(selectedItem.type){
    case "Sheet Stock":
    case "Roll Stock":
    case "Envelope":
      list = await axios.get(`https://highgroundapi.herokuapp.com/paper/group/${selectedItem.type}`)
     .then(response => {
      console.log(response.data)
      setItemList(response.data)
      //setPapersize(true)
     })
     .catch(err => console.log(err))
      break;
      case "Ink":
      list = await axios.get(`https://highgroundapi.herokuapp.com/ink`)
     .then(response => setItemList(response.data))
     .catch(err => console.log(err))
      break;
      default :
      setItemList([])
      //setPrepoitem({...prepoitem,item:""})
     // setPapersize(false)
      }
    }
    getItems();
},[selectedItem.type])


useEffect(() => {

  let neww;
  let length = parseFloat(selectedItem.length);
  let width = parseFloat(selectedItem.width);
  let weight = parseFloat(selectedItem.weight);
  let divide = parseFloat(selectedItem.divide);
  let a;

    switch(selectedItem.priceunit){
      case "PER M":
        a = (selectedItem.quantity / 1000) * selectedItem.unitprice
        setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})
       /*   a = length * width * weight * 2;
          a /= divide;
          a *= selectedItem.quantity
          a /= 1000
          a *= selectedItem.unitprice
          a /= 100
          setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})*/
          break;
          case "CWT":
 
            a = (selectedItem.quantity / 100) * selectedItem.unitprice
            setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})
            break;
          default:
            a = selectedItem.quantity * selectedItem.unitprice;
            setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})
    }
  
    
  },[selectedItem.quantity,selectedItem.unitprice,selectedItem.priceunit])

  


const changeVendor = (e) => {

    var inputValue = e.target.value.toLowerCase();

    const blah = vendorList?.filter(item => item.company.name?.toLowerCase() === inputValue)
   
     if(blah.length === 0){
      
       setNewselectedVendor({...newselectedVendor => [{...newselectedVendor.company,name:e.target.value}]})
       setChanges(true)
       
     }else{
      setNewselectedVendor(blah[0])
       //setReload(!reload)
       setChanges(true)
     }

  if(!newpoinfo.ponum && blah.length > 0){
  const info = {
    dueDate:newpoinfo.dueDate,
    dueOrigDate:newpoinfo.dueOrigDate,
    poDate:newpoinfo.poDate,
    poOrigDate:newpoinfo.poOrigDate,
    vendor:blah[0]._id
  }
    axios.post("https://highgroundapi.herokuapp.com/po/add",info)
    .then(res => {setNewpoinfo({...newpoinfo,ponum:res.data.po.ponum,id:res.data.po._id});toast.success(`PO ${res.data.po.ponum} has been created`)})
    .catch(err => console.log(err))

  }
  if(newpoinfo.ponum && blah.length > 0){
    const info = {
    id:newpoinfo.id,
    value:blah[0]._id,
    feild:"vendor"
    }
      axios.post("https://highgroundapi.herokuapp.com/po/updateitem",info)
      .then(res => {setNewpoinfo({...newpoinfo,vendor:blah[0]._id});toast.success('Vendor Updated')})
      .catch(err => console.log(err))

    }

 

  
  
  

 



}



const switchItem = async(e) => {
 setSelectedItem({...selectedItem, type:e.target.value})
}

const setitem = async(e) => {

  var inputValue = e.target.value;
  if(selectedItem.type === "Building Maintanence" || selectedItem.type === "Shipping" || selectedItem.type === "Outside Service"){
  var inputValue = e.target.value;
  setSelectedItem({...selectedItem,itemname:inputValue})
  }else{
  let id;
  let cat;
  let value;
    
      var options = document.getElementById("items").childNodes;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value === inputValue) {
          id = (options[i].dataset.item);
          cat = (options[i].dataset.cat);
          value = (e.target.value);
        //  console.log(value)

   await axios.get(`https://highgroundapi.herokuapp.com/${cat}/${id}`)
  .then(response => {
  
console.log(response.data)

  
  switch(cat){
  case "ink":
    setSelectedItem({...selectedItem,unitprice:response.data.price,orderby:response.data.defaultorderby,priceunit:response.data.defaultpriceunit,item:id,itemname:response.data.pressname+" "+response.data.type+" "+response.data.color,stockid:response.data.id})
    setBaseitem("ink")
    break;
    case "paper":
      setSelectedItem({...selectedItem,unitprice:response.data.price,orderby:response.data.defaultorderby,priceunit:response.data.defaultpriceunit,item:id,itemname:response.data.name,weight:parseInt(response.data.weight),divide:response.data.divide,stockid:response.data.id,category:response.data.category})
      setBaseitem("paper")
    break;
    default:
      setSelectedItem({...selectedItem,itemname:inputValue})
  }
   
  })
  .catch(err => console.log(err))
          break;
    }else{
      setSelectedItem({...selectedItem, 
        divide: 0,
    stockid:"",
  extprice: 0,
  item: "",
  itemname: e.target.value,
  jobnum: "",
  length: "",
  orderby: "",
  priceunit: "",
  quantity: 0,
  type: selectedItem.type,
  unitprice:0,
  weight: 0,
  width: "",
  category:"",
notes:""})
        
    }
 
  } 
  
  
  
}
  }


 


const notify = (item) => toast(item);

const update = async(e) => {
  e.preventDefault()

  if(selectedItem.id){
const objIndex = items.findIndex((obj => obj.id === selectedItem.id));
items[objIndex] = selectedItem
  }else{
    const newItem = {
      divide: selectedItem.divide ,
      extprice: selectedItem.extprice ,
      id: uuidv4(),
      item: selectedItem.item , 
      itemname: selectedItem.itemname , 
      jobnum: selectedItem.jobnum ,
      length: selectedItem.length , 
      orderby: selectedItem.orderby ,
      priceunit: selectedItem.priceunit ,
      quantity: selectedItem.quantity , 
      type: selectedItem.type , 
      unitprice: selectedItem.unitprice ,
      weight: selectedItem.weight , 
      width: selectedItem.width,
      category:selectedItem.category,
      notes: selectedItem.notes 

    }
setItems(items => [...items,newItem])
toast.success(`${selectedItem.itemname}  has been added`)
setPosavecheck(true)

  }
  setSelectedItem(initialSelectedItems)
  setItemList([])
}



  const updateItem = (id,feild,value) => {

    const updateinfo = {
      id,
      feild,
      value
    }

    
    
        axios.post(`https://highgroundapi.herokuapp.com/${baseitem}/update`, updateinfo)
        .then(response => {toast.success(`${selectedItem.itemname} - ${feild} has been updated`)})
       
     
        .catch(err => console.log(err))
       
    
  
  }

const checkVendor = (e) => {
  /*
  if(e.target.value === ""){
    return errorset({
      active:true,
      header:"Vendor Error",
      message:"You must choose a vendor.",
    });

  }
  if(newpoinfo.vendor === ""){
   return errorset({
      active:true,
      header:"Vendor Error",
      message:"Vendors must be added to system before use. If you are trying to create a dummy PO please use PLS Print as your vendor. Aternativly, Click add new vendor button to add to system",
    })
  }
  */
}
//console.log(selectedVendor)
//console.log(items)


const deleteItem = (value) => {
  setItems(items.filter(item => item.id !== value))
  setPosavecheck(true)
}



const updatePO = () => {
  const info = {
    id:newpoinfo.id,
    value:items,
    feild:"items"
    }
      axios.post("https://highgroundapi.herokuapp.com/po/updateitem",info)
      .then(res => {toast.success('PO Saved')})
      .then(res => setPosavecheck(false))
      .catch(err => console.log(err))
}


console.log(items)

  return (
  
    <Wrap>
 <ToastContainer position="bottom-center" theme="dark"/>
<Modal show={editvendor} header={`Edit ${newselectedVendor.company?.name } Information`} onCancel={() => {setEditvendor(false)}} ><EditVendor id={newselectedVendor?._id}  onCancel={() => {setEditvendor(false)}}  newselectedVendor={newselectedVendor}  setSelectedVendor={setNewselectedVendor} selectedVendor={newselectedVendor} setChanges={setChanges} /></Modal>





<form style={{marginTop:"25px"}} key={te}>

<Grid grid='"edit ponum duedate podate notes notes" 
            "vendor vendor  vendorid type notes notes" 
            "address1 address1 address2 address2 notes notes" 
            "city state zip space notes notes" 
            "website website phone fax notes notes" 
            '
        
            cols='repeat(6, 1fr)'>
<div className="edit" >{newselectedVendor._id && <Button type="button" onClick={() => setEditvendor(true)}>Edit Vendor</Button>}</div>
<div className="ponum">PO Num:<br /> <Input type="text" width="50%" value={newpoinfo?.ponum}  disabled={true} /></div>
<div className="vendor">Vendor: <br />
<Input  type="text" list="vendors" onChange={changeVendor} value={newselectedVendor.company?.name} onBlur={e => checkVendor(e)} required/>
<datalist id="vendors">
{
  vendorList?.map(vendor => (
    <option key={vendor._id} data-cid={vendor.company?.company_id} value={vendor.company?.name}/>
  ))
}
</datalist>
</div>
<div className="vendorid">Vendor ID: <br /><Input type="text"  value={newselectedVendor.company?.company_id}  disabled={true}/></div>
<div className="type">Type <br />
<Input type="text" list="vendorTypes" value={newselectedVendor?.type} placeholder="Select Type" required disabled={true}/>
<datalist id="vendorTypes">
{
  vendorTypes?.map(vendor => (
    <option key={vendor.value} value={vendor.value} />
  ))
}
</datalist></div>
<div className="space"></div>
<div className="duedate">Created On: <br /><Datepick selected={newpoinfo.poDate && new Date(newpoinfo?.poDate)} /></div>
<div className="podate">Due Date: <Datepick  selected={newpoinfo.dueOrigDate && new Date(newpoinfo?.dueOrigDate)} onChange={(date:Date) =>  setNewpoinfo({...newpoinfo,dueOrigDate:date,dueDate:date.toISOString()})} /></div>
<div className="address1">Address 1: <Input type="text"  defaultValue={newselectedVendor.address?.address1} disabled={true}/></div>
<div className="address2">Address 2: <Input type="text" defaultValue={newselectedVendor.address?.address2} disabled={true}/></div>
<div className="city">City: <Input type="text" defaultValue={newselectedVendor.address?.city} disabled={true}/></div>
<div className="state">State: <Input type="text" defaultValue={newselectedVendor.address?.state} disabled={true}/></div>
<div className="zip">Zip: <Input type="text" defaultValue={newselectedVendor.address?.zip} disabled={true}/></div>
<div className="website">Website: <Input type="text" defaultValue={newselectedVendor.website} disabled={true} /></div>
<div className="phone">Phone: <Input type="text" defaultValue={newselectedVendor.numbers?.main} disabled={true}/></div>
<div className="fax">Fax: <Input type="text" defaultValue={newselectedVendor.numbers?.fax} disabled={true}/></div>

<div className="notes">Notes:<Textarea rows="5"  onChange={() => alert()} defaultValue={newselectedVendor.notes ?newselectedVendor.notes : ""}  disabled={true} /></div>
</Grid>


</form>

<Flexed>
  <Flex style={{marginTop:"15px"}} flex="1"><b>Items in Purchase Order  {newpoinfo?.ponum}:</b> <span style={{color:"black",fontWeight:"bold"}}> </span> </Flex>
  <Flex flex="1" textAlign="center" alignItems="center" fontSize="calc(1.2rem + .5vw)" color="rgb(255,0,0,.6)" fontWeight="bold">{posavecheck && <span>PO NOT SAVED</span>}</Flex>
  <Flex flex="1" justifyContent="flex-end" display="flex" >{newpoinfo.ponum &&  <>{!posavecheck && <PrintComponent id={newpoinfo.ponum} to={newselectedVendor} items={items}  total={total}/>}</>}<Button onClick={updatePO}>Save PO</Button></Flex>
  </Flexed>




<div className="" style={{width:"100%",overflowX:"scroll",border:"1px solid var(--plsGrey)"}}>

<table className="sortable hand" style={{width:"200%",borderCollapse:"collapse"}}>
<thead>
<tr style={{display:"flex",justifyContent:"flex-start"}}>
<th style={{flex:"1",padding:"3px 0px"}}>Job#</th>
<th style={{flex:"2",padding:"3px 0px"}}>Item</th>

<th style={{flex:"1",padding:"3px 0px"}}>Ordered</th>
<th style={{flex:"1",padding:"3px 0px"}}>Recieved</th>
<th style={{flex:"1",padding:"3px 0px"}}>Cost</th>
<th style={{flex:"1",padding:"3px 0px"}}>Total</th>
<th style={{flex:"1",padding:"3px 0px"}}>Date Ordered</th>
<th style={{flex:"1",padding:"3px 0px"}}>Due Date</th>
<th style={{flex:"1",padding:"3px 0px"}}>Received Date</th>
<th style={{flex:".5",padding:"3px 0px"}}>Size</th>
<th style={{flex:".5",padding:"3px 0px"}}>Invoiced</th>
<th style={{flex:"3",padding:"3px 0px"}}>Notes</th>

</tr>
</thead>
 <tbody style={{height:"150px",fontSize:"1rem"}} id="polistitems">

 {


 items.map((item,index) => {


  return <tr key={index} style={{display:"flex",borderBottom:"1px solid var(--plsGrey)"}} onClick={() => setSelectedItem(item)}>
      <td style={{flex:"1",padding:"3px 0px 3px 5px"}}>{item.jobnum}</td>
  <td style={{flex:"2",padding:"3px 0px"}}>{item.itemname}</td>

  <td style={{flex:"1",padding:"3px 0px"}}>{item.quantity}&nbsp;{item.orderby}</td>
  <td style={{flex:"1",padding:"3px 0px"}}>{item.received}</td>
  <td style={{flex:"1",padding:"3px 0px"}}>{currency(item.unitprice)} per {item.priceunit}</td>
  <td style={{flex:"1",padding:"3px 0px"}}>{currency(item.extprice)}</td>
  <td style={{flex:"1",padding:"3px 0px"}}></td>
<td style={{flex:"1",padding:"3px 0px"}}></td>
<td style={{flex:"1",padding:"3px 0px"}}></td>
  <td style={{flex:".5",padding:"3px 0px"}}>{item.length ? item.length+" x "+item.width : ""}</td>
  <td style={{flex:".5",padding:"3px 0px"}}></td>
  <td style={{flex:"3",padding:"3px 0px"}}>{item.notes}</td>
  </tr>  
})}
 <tr style={{display:"flex"}}>
      <td style={{flex:"1",padding:"3px 0px"}}></td>
  <td style={{flex:"2",padding:"3px 0px"}}></td>

  <td style={{flex:"1",padding:"3px 0px"}}></td>
  <td style={{flex:"1",padding:"3px 0px"}}></td>
  <td style={{flex:"1",padding:"3px 0px",textAlign:"right"}}>Total:&nbsp;&nbsp;&nbsp;&nbsp;</td>
  <td style={{flex:"1",padding:"3px 0px"}}>{currency(total)}</td>
  <td style={{flex:"1",padding:"3px 0px"}}></td>
<td style={{flex:"1",padding:"3px 0px"}}></td>
<td style={{flex:"1",padding:"3px 0px"}}></td>
  <td style={{flex:".5",padding:"3px 0px"}}></td>
  <td style={{flex:".5",padding:"3px 0px"}}></td>
  <td style={{flex:"3",padding:"3px 0px"}}></td>
  </tr>  
</tbody>
<tfoot></tfoot></table>

</div>






<form style={{position:"relative",border:"1px solid #648ca7",padding:"20px 5px"}} onSubmit={update}>

<Flexed gap="10px">

<Flex><Label>Item Type</Label><br />
  <Input type="text" list="vendorTypes" onChange={switchItem}  placeholder="Select Item" value={selectedItem.type} required/>
<datalist id="vendorTypes">
{
vendorTypes?.map(vendor => (
<option key={vendor.value} value={vendor.value} />
))
}
</datalist></Flex>
<Flex flex="3"><Label>Items:&nbsp; {itemList.length}</Label><br />
{itemList.length > 0 ? <><Input type="text" list="items" id="listofitems" onChange={setitem} value={selectedItem?.itemname} name="item" placeholder="Select Item" required/>
<datalist id="items">
{
 itemList?.map((item,index) => {
  switch(selectedItem.type){
    case "Sheet Stock":
      case "Roll Stock":
      case "Envelope":
return  <option key={index} data-item={item._id} data-cat="paper" value={item.name} />
        break;
        case "Ink":
    return  <option key={index} data-item={item._id} data-cat="ink" value={item.pressname+" "+item.type+" "+item.color} />
            break;
        default :
  }
})
}
</datalist>
</> 
:
<><Input type="text"  onChange={setitem} value={selectedItem?.itemname} name="item" placeholder="Enter Item" required/></>}


</Flex>

       
<Flex flex=".5"><Label>ID<br /><Input type="text" placeholder="Stock ID" value={selectedItem.stockid}/></Label></Flex>
<Flex flex=".7"><Label>Item Order By</Label><br />
<Input type="text" list="orderbylist" onChange={e => setSelectedItem({...selectedItem,orderby:e.target.value})} value={selectedItem.orderby} placeholder="Order By" required/>
<datalist id="orderbylist">
{
  orderbylist?.map((item,index) => (
    <option key={index} value={item} />
  ))
}
</datalist>
<Button type="button" padding="6px 13px" style={{position:"absolute",bottom:"-28px",right:"0"}} onClick={() => updateItem(selectedItem.item,"defaultorderby",selectedItem.orderby)}>Update Order By</Button></Flex>
</Flexed>

{selectedItem.type === "Sheet Stock"  ?  <Flexed gap="10px" justifyContent="flex-start" margin="20px 0px 0px 0px"><Flex flex=".3"   textAlign="center"><Label>L</Label><br /><Input type="text" placeholder="Length" value={selectedItem.length} onChange={e => setSelectedItem({...selectedItem,length:e.target.value})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>
<Flex flex=".3"  textAlign="center"><Label>W</Label><br /><Input type="text" value={selectedItem.width} onChange={e =>  setSelectedItem({...selectedItem,width:e.target.value})} placeholder="Width"  /></Flex>
<Flex flex=".3"   textAlign="center"><Label>Weight</Label><br /><Input type="text" placeholder="Weight" value={selectedItem.weight} onChange={e => setSelectedItem({...selectedItem,weight:parseInt(e.target.value)})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>
<Flex flex=".3"   textAlign="center"><Label>Weight Basis</Label><br /><Input type="text" placeholder="Weight Basis" value={selectedItem.divide} onChange={e => setSelectedItem({...selectedItem,divide:e.target.value})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>
<Flex flex="1"></Flex></Flexed> : ''}

 <Flexed gap="10px"padding="35px 0px">
  <Flex flex=".7"><Label>Job #</Label><br />
      <Input type="text"  onChange={e => setSelectedItem({...selectedItem,jobnum:e.target.value})} value={selectedItem.jobnum} placeholder="Job #" />
</Flex>
    <Flex><Label>Quantity</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,quantity:parseInt(e.target.value)})} value={selectedItem?.quantity} placeholder="Quantity" /></Flex>
    <Flex ><Label>Unit Price</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,unitprice:parseFloat(e.target.value)})} value={selectedItem?.unitprice} placeholder="Unit Price" /><Button type="button" onClick={e => updateItem(selectedItem.item,"price",selectedItem.unitprice)}  style={{position:"absolute",bottom:"-28px",right:"0"}}>Update Unit Price</Button></Flex>
    <Flex flex=".7"><Label>Price Unit</Label><br />
      <Input type="text" list="priceu" onChange={e => setSelectedItem({...selectedItem,priceunit:e.target.value})} value={selectedItem.priceunit} placeholder="Price Unit"  required/>
<datalist id="priceu">
{
  priceunits?.map((item,index) => (
    <option key={index} value={item} />
  ))
}
</datalist><Button type="button" onClick={e => updateItem(selectedItem.item,"defaultpriceunit",selectedItem.priceunit)} style={{position:"absolute",bottom:"-28px",right:"0"}}>Update Price Unit</Button></Flex>
    <Flex><Label>Ext Price</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,extprice:e.target.value})} value={selectedItem?.extprice} placeholder="Ext Price" /></Flex>
  </Flexed>
  <Flexed padding="5px 0px" alignItems="flex-end">
  <Flex flex="1"><Label>Item Notes</Label><br /><Textarea rows="5"  onChange={e => setSelectedItem({...selectedItem,notes:e.target.value})} value={selectedItem.notes} ></Textarea></Flex>
    <Flex></Flex>
    <Flex justifyContent="flex-end" display="flex"  padding="15px"><Button bgcolor="var(--plsBlue)"  color="white" style={{marginRight:"25px"}}>{selectedItem.id === "" ?  'Add Item' : 'Update Item' }</Button>  <Button bgcolor="yellow"  color="var(--plsBlue)" style={{marginRight:"25px"}} type="button" onClick={() => setSelectedItem(initialSelectedItems)}>Clear Item</Button><DeleteForeverIcon style={{fontSize:"35px",color:"rgb(255,0,0,.6)",marginRight:"50px"}}onClick={e => {deleteItem(selectedItem.id)}} /></Flex>
  </Flexed>
  
</form>




<br /><br /><br /><br /><br /><br /><br />

<hr style={{border:"1px solid inherit",marginTop:"15px"}} />

<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
  </Wrap>

  )
}

export default Addpo