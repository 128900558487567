
import { useTable, useSortBy, useGlobalFilter,useFilters } from 'react-table'
import {Flexed,Flex, Selectbox} from '../../Shared'
import { useMemo,useState,useEffect } from 'react'
import axios from 'axios'
//import DATA from './DATA.json'
import GlobalFilter from './GlobalFilter'
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";
/*
export const MainTable = styled.table`
width:100%;
table-layout:fixed;
border:1px solid blue;

`;
export const TableHead = styled.thead`

text-align: center;
background-color: #7ABD42;
color: white;

tr{
    display:"flex"
}

th{
font-size:calc(.6rem + .5vw);
    padding:5px;
    position:relative;
    text-align:left;
    flex:${props => props.flex || "1"};
    word-break:break-all;
}
}

`;
const TableBody = styled.tbody`


`;
*/
export const TableWrap = styled.table`
font-family: Arial, Helvetica, sans-serif;
border-collapse: collapse;
width: 100%;
border:1px solid rgb(0,0,0,.2);
margin-top:10px;
tr{
   display:flex;
   :nth-child(even){background-color: #f2f2f2;}
}
   tbody tr:hover{
    cursor:pointer;
   
    background-color:rgb(122,187,67,.5);

   }
th{
   padding:3px 5px;
  border-bottom:1px solid rgb(0,0,0,.2);
}
 
`;

export const TH = styled.th`
position:relative;
flex:${props => props.flex || "1"};
word-break:break-all;
background-color:rgb(122,187,67,.3);
text-align:left;
`;

export const TD = styled.td`
flex:${props => props.flex || "1"};
padding:5px;
word-break:break-all;
border:1px solid rgb(0,0,0,.2);



`;
const Table = ({supplyColumns,DATA,setList,second}) => {

    let navigate = useNavigate();  


const columns = useMemo(() => supplyColumns, [])
const data = useMemo(() => DATA,[DATA])
const [paper, setPaper] = useState(false)
console.log(columns)

console.log(data)

const TableInstance = useTable({
    columns,
    data
},
useFilters,
useGlobalFilter,
useSortBy)

const {getTableProps,getTableBodyProps,headerGroups,rows,prepareRow,state,setGlobalFilter} = TableInstance
const { globalFilter } = state

useEffect(() => {
    switch(second){
case 'paper':
    setPaper(true)
    break;
    }
})


  return (
    <div style={{position:"relative"}}>
<Flexed justifyContent="space-between" alignItems="center" padding="10px 0px">
    <Flex flex="2"textAlign="left"> <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /></Flex>
    {paper && <Flex flex=".7" textAlign="left" > <Selectbox onChange={e => setList(e.target.value)}><option value="">All</option>
<option value="yes">Coated</option>
<option value="no">Uncoated</option></Selectbox></Flex>}
    <Flex textAlign="right">Add New Item</Flex>
</Flexed>
   
 
    <TableWrap {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TH  flex={column.Flex} {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}
                <div>{column.canFilter ? column.render('Filter') : null}</div>
                  <span style={{position:"absolute",right:"5px",top:"1px"}}>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </TH>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} onClick={() => navigate(`/inventory/${row.original._id}`)}>
                {row.cells.map(cell => {
                  return <TD flex={cell.render('Flex')} {...cell.getCellProps()}>{cell.render('Cell')}</TD>
                })}
              </tr>
            )
          })}
        </tbody>
       
      </TableWrap>
      </div>
  )
}

export default Table