import styled from 'styled-components'
import 'react-tabs/style/react-tabs.css';
import {useContext,useState,useEffect,useCallback} from 'react'
import { Grid,ItemListArea, Items,Polower } from './PoStyles'
import {Flexed,Flex,Img,Input,Datepick,Label,currency,Textarea,Button,PhoneNumber} from '../../Shared'
import {UserContext} from '../../context/UserContext';
//import Poad from './poad'
import Modal from '../../components/Modal/Modal';
import EditVendor from '../../components/vendors/EditVendor';
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
  import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Receive from './Receive';
import PrintComponent from './PrintComponent';
import Moment from 'react-moment';
import { unstable_composeClasses } from '@mui/material';


const Wrap = styled.div`
width:100%;
min-height:100vh;

th,td{

  text-align:left;
}
thead > tr:first-child{
border-bottom:1px solid var(--plsGrey)
}

thead tr{
  background-color:rgb(100,140,167,.3);
padding:3px 0px;
color:var(--plsGrey);
}
tbody tr{
padding:3px 0px;
}

.active{
  background-color:black;
}
.error{
  border:2px solid red;
}
`;






const PO = ({currentSelectedPo,errorset,errors,setComp,id}) => {
  const initialvendor = {
    address:{
      address1:"",
      address2:"",
      city:"",
      state:"",
      zip:""
    },
    company:{
      company_id:"",
      dba:"",
      name:""
    },
    numbers:{
      fax:"",
      main:"",
      other:""
    },
    type:"",
    website:"",
    _id:"",
    notes:""
  }

   const [recStatus, setRecStatus] = useState([]);
    const [vendorTypes, setVendorTypes] = useState([]);
    const [vendorList, setVendorList] = useState([]);
const [newpoinfo, setNewpoinfo] = useState({});
const [items, setItems] = useState([]);
const initialSelectedItems = {
    divide: "0",
    stockid:"",
  extprice: 0,
  id: "",
  item: "",
  itemname: "",
  jobnum: "",
  length: "",
  orderby: "",
  priceunit: "",
  quantity: "0",
  type: "",
  unitprice:"0",
  weight:"0",
  width: "",
  category:"",
  notes:""

  }

 



const [selectedItem, setSelectedItem] = useState(initialSelectedItems);





const [selectedVendor, setSelectedVendor] = useState(currentSelectedPo.vendor);
const {userInfo} = useContext(UserContext)
const [editvendor, setEditvendor] = useState(false)
const [good, setGood] = useState(true)
const [changes, setChanges] = useState(false)
const [updateitem, setUpdateitem] = useState(false)
const [itemList, setItemList] = useState([])
const [baseitem, setBaseitem] = useState("")
const [receive, setReceive] = useState(null)
const [recerrors, setRecerrors] = useState([])
const [recerrorsid, setRecerrorsid] = useState([])
const [receiveform, setReceiveform] = useState(false)
const [reload, setReload] = useState(false)
const [editpermissions, setEditpermissions] = useState(false)
const [tempVendor, setTempVendor] = useState(false)
const [total, setTotal] = useState(0)
const [closed, setClosed] = useState(currentSelectedPo.status)
const orderbylist= ["Sheets","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]
const priceunits= ["PER M","CWT","Sheet","LBS","Each","Case","Box","Barrel","Canister","Lot","/ HR"]




useEffect(() => {
const getVendors = async() => {
await axios.get("https://highgroundapi.herokuapp.com/vendor")
 .then(response => setVendorList(response.data))
   .catch(err => console.log(err))
  }
  const getpo = async() => {
    const por = {"id":id}
    await axios.post('https://highgroundapi.herokuapp.com/po/getpo',por)
    .then(response => {
  setNewpoinfo(response.data)
setSelectedVendor(response.data.vendor)
if(response.data.items.length > 0){
  setItems(response.data.items)
}else{
  setItems([]) 
}
  

    })
    .catch(err => console.log(err))
    }
 getVendors();
    getpo()
 
},[reload,editvendor,receiveform])




useEffect(() => {
        const getVendorTypes = async() => {
            await axios.get("https://highgroundapi.herokuapp.com/list/vendorTypes")
             .then(response => setVendorTypes(response.data.vendor.items))
             .catch(err => console.log(err))
             }
        getVendorTypes();
},[selectedVendor])


useEffect(() => {
  const getItems = async() => {
    let list;
  switch(selectedItem.type){
    case "Sheet Stock":
    case "Roll Stock":
    case "Envelope":
      list = await axios.get(`https://highgroundapi.herokuapp.com/paper/group/${selectedItem.type}`)
     .then(response => {
      setItemList(response.data)
      //setPapersize(true)
     })
     .catch(err => console.log(err))
      break;
      case "Ink":
      list = await axios.get(`https://highgroundapi.herokuapp.com/ink`)
     .then(response => setItemList(response.data))
     .catch(err => console.log(err))
      break;
      default :
      setItemList([])
      //setPrepoitem({...prepoitem,item:""})
     // setPapersize(false)
      }
    }
    getItems();
},[selectedItem.type])



/*useEffect(() => {
  let test = 0;
items.map(item => {
  if(item.status > 0){
    console.log(item.status)
test += 1;
  }
})
if((items.length - test) === 0){
setClosed(true)
}else{
  setClosed(false)  
}
},[items])
*/


const get = () => {
  let totalt = 0;
  items.map(item => {
    totalt += parseFloat(item.extprice)
  })
  setTotal(totalt)
}

useEffect(() => {get()},[items])
useEffect(() => {

  let neww;
  let length = parseFloat(selectedItem.length);
  let width = parseFloat(selectedItem.width);
  let weight = parseFloat(selectedItem.weight);
  let divide = parseFloat(selectedItem.divide);
  let a;

    switch(selectedItem.priceunit){
      case "PER M":
        a = (selectedItem.quantity / 1000) * selectedItem.unitprice
        setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})
       /*   a = length * width * weight * 2;
          a /= divide;
          a *= selectedItem.quantity
          a /= 1000
          a *= selectedItem.unitprice
          a /= 100
          setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})*/
          break;
          case "CWT":
            a = (selectedItem.quantity / 100) * selectedItem.unitprice
            setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})
            break;
          default:
            a = selectedItem.quantity * selectedItem.unitprice;
            setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})
    }
   get()
  },[selectedItem.quantity,selectedItem.unitprice,selectedItem.priceunit])


useEffect(() => {
  if(newpoinfo?.status === 0){
let re = 0
items.map(item => {
  if(item.status === 1){
    re += 1
  }
 const ret = items.length - re

 if(ret === 0){
 
  manualPOclse()

 }
})

}

},[newpoinfo,newpoinfo.status])




const manualPOclse = async() => {
  const info = {
    id:newpoinfo._id,
    value:1,
    feild:"status"
    }
  await axios.post("https://highgroundapi.herokuapp.com/po/updateitem",info)
  .then( () => setClosed(true))
  .then(() =>   toast.warning('This PO has been changed to closed.'))
  .then(() => setSelectedItem(initialSelectedItems))
     .catch(err => console.log(err))
}

const savePO = () => {

  const info = {
    id:newpoinfo._id,
    value:items,
    feild:"items"
    }
      axios.post("https://highgroundapi.herokuapp.com/po/updateitem",info)
      .then(res => {toast.success('PO Saved')})
      .then(() => {setChanges(false)})
      .catch(err => console.log(err))//toast.warning(err.response.data.message))  
}

const switchItem = async(e) => {
 setSelectedItem({...selectedItem, type:e.target.value})
}

const setitem = async(e) => {
  var inputValue = e.target.value;
  if(selectedItem.type === "Building Maintanence" || selectedItem.type === "Shipping" || selectedItem.type === "Outside Service"){
    var inputValue = e.target.value;
    setSelectedItem({...selectedItem,itemname:inputValue})
    }else{
  let id;
  let cat;
  let value;
   
      var options = document.getElementById("items").childNodes;
      for (var i = 0; i < options.length; i++) {
        if (options[i].value === inputValue) {
          id = (options[i].dataset.item);
          cat = (options[i].dataset.cat);
          value = (e.target.value);
        //  console.log(value)

   await axios.get(`https://highgroundapi.herokuapp.com/${cat}/${id}`)
  .then(response => {

  switch(cat){
  case "ink":
    setSelectedItem({...selectedItem,unitprice:response.data.price,orderby:response.data.defaultorderby,priceunit:response.data.defaultpriceunit,item:id,itemname:response.data.pressname+" "+response.data.type+" "+response.data.color,notes:response.data.notes})
    setBaseitem("ink")
    break;
    case "paper":
      setSelectedItem({...selectedItem,unitprice:response.data.price,orderby:response.data.defaultorderby,priceunit:response.data.defaultpriceunit,item:id,itemname:response.data.name,weight:parseInt(response.data.weight),divide:response.data.divide,stockid:response.data.id,category:response.data.category})
      setBaseitem("paper")
    break;
    default:
      setSelectedItem({...selectedItem,unitprice:0,orderby:response.data.defaultorderby,priceunit:response.data.defaultpriceunit,item:id,itemname:response.data.pressname+" "+response.data.type+" "+response.data.color,notes:response.data.notes})
  }
   
  })
  .catch(err => console.log(err))
          break;
    }else{
      setSelectedItem({...selectedItem, 
        divide: 0,
    stockid:"",
  extprice: 0,
  item: "",
  itemname: e.target.value,
  jobnum: "",
  length: "",
  orderby: "",
  priceunit: "",
  quantity: 0,
  type: selectedItem.type,
  unitprice:0,
  weight: 0,
  width: "",
  category:"",
notes:"",
condition:""})
        
    }
 
  } 
  
}
  
  
  }


 

  

 


const notify = (item) => toast(item);

const update = async(e) => {
  e.preventDefault()

  if(selectedItem.id){
const objIndex = items.findIndex((obj => obj.id === selectedItem.id));
items[objIndex] = selectedItem
setSelectedItem(initialSelectedItems)
toast.success(`${selectedItem.itemname}  has been updated`)
setChanges(true)
setItemList([])

  }else{
    const newItem = {
      divide: selectedItem.divide ,
      extprice: selectedItem.extprice ,
      id: uuidv4(),
      item: selectedItem.item , 
      itemname: selectedItem.itemname , 
      jobnum: selectedItem.jobnum ,
      length: selectedItem.length , 
      orderby: selectedItem.orderby ,
      priceunit: selectedItem.priceunit ,
      quantity: selectedItem.quantity , 
      type: selectedItem.type , 
      unitprice: selectedItem.unitprice ,
      weight: selectedItem.weight , 
      width: selectedItem.width,
      category: selectedItem.categoey,
      notes:selectedItem.notes
    }
setItems(items => [...items,newItem])
toast.success(`${selectedItem.itemname}  has been added`)
setChanges(true)
setSelectedItem(initialSelectedItems)
setItemList([])

  }
 
}



const updatePOInfo = (id,feild,value) => {
  const updateinfo = {
    id,
    feild,
    value
  }
      axios.post(`https://highgroundapi.herokuapp.com/po/update`, updateinfo)
      .then(response => {toast.success(`PO has been updated`)})
   .catch(err => console.log(err))
}


  const updateItem = (id,feild,value) => {
    const updateinfo = {
      id,
      feild,
      value
    }
        axios.post(`https://highgroundapi.herokuapp.com/${baseitem}/update`, updateinfo)
        .then(response => {toast.success(`${selectedItem.itemname} - ${feild} has been updated`)})
     .catch(err => console.log(err))
  }

  const changevendor = (e) => {
    var inputValue = e.target.value.toLowerCase();
    const vendor = vendorList?.filter(item => item.company.name?.toLowerCase() === inputValue)
if(vendor.length === 0){
  setSelectedVendor({})
  setChanges(false)
}else{
if(vendor[0].company.company_id !== newpoinfo.vendor.company.company_id){
  
  setSelectedVendor(vendor[0])
  setChanges(false)
}else{
  setSelectedVendor(vendor[0])
  setChanges(false)
}
}}

const savenewvendor = () => {
  if(newpoinfo.ponum && selectedVendor.company?.company_id > 0){
    const info = {
    id:newpoinfo._id,
    value:selectedVendor._id,
    feild:"vendor"
    }
      axios.post("https://highgroundapi.herokuapp.com/po/updateitem",info)
      .then(res => setReload(!reload))
      .then(res => {setChanges(false); toast.success(`Vendor changed for this PO`)})
      .catch(err => console.log(err))
    }else{
      toast.warning(`No vendor selected`)
    }}


//console.log(recerrorsid)

//console.log(selectedVendor)
//console.log(items)
//console.log(selectedItem)






const deleteItem = (value) => {
  setItems(items.filter(item => item.id !== value))
  setChanges(true)
}

const setrecitemcolor = (num) => {
let color;
switch(num){
  case 1:
   color =  {"bg":"rgb(0,255,0,.6)","text":"grey"}
    break;
  case 2:
    color =  {"bg":"rgb(255,255,0,.6)","text":"rgb(255,0,0,.7)"}
  break;
  case 2:
    color =  {"bg":"rgb(255,255,0,.6)","text":"grey"}
  break;
  default:
    color =  {"bg":"inherit","text":"inherit"}
}
return color
}

  return (
  
    <Wrap>
 <ToastContainer position="bottom-center" theme="dark" autoClose={9000}/>
<Modal show={editvendor} header={`Edit ${newpoinfo.vendor?.company?.name} Information`} onCancel={() => {setEditvendor(false)}} ><EditVendor onCancel={() => {setEditvendor(false)}}  selectedVendor={selectedVendor} setSelectedVendor={setSelectedVendor} reload={reload} setReload={setReload}/></Modal>
<Modal show={receiveform} header={`Receiving PO ${newpoinfo.ponum} `} onCancel={() => {setReceiveform(false)}} ><Receive id={newpoinfo._id}  items={items} onCancel={() => {setReceiveform(false)}} po={newpoinfo.ponum} setRecerrorsid={setRecerrorsid} setRecerrors={setRecerrors} recerrors={recerrors} selectedItem={selectedItem}/></Modal>

<div style={{position:"sticky",top:"219px",width:"100%",backgroundColor:"#EDEEF2",zIndex:"3"}}>
{changes &&
  <Flexed padding="10px 0px">
      <Flex textAlign="left"></Flex>
    <Flex style={{fontWeight:"bold",fontSize:"calc(1.3rem + .5vw)",textAlign:"center",width:"100%",backgroundColor:"#EDEEF2",color:"rgb(255,0,0,.7)"}}>PO NOT SAVED</Flex> 
   <Flex display="grid" justifyContent="flex-end" alignItems="center">
   
  <div flex="fit-content" justifyContent="flex-end"  display="flex" >{selectedVendor.company?.company_id && <Button onClick={savePO} style={{marginLeft:"20px",backgroundColor:"rgb(122,189,66,.5)"}} >Save PO</Button>}</div>

   </Flex>
    </Flexed>
}
    <Flexed style={{marginTop:"15px",}}>
  <Flex textAlign="left">PO# <span style={{fontSize:"calc(1.2rem + .5vw)"}}>{newpoinfo?.ponum}</span></Flex>
  <Flex><p><span style={{color:"black",fontWeight:"bold",fontSize:"calc(.7rem + .5vw)"}}>{newpoinfo.vendor?.company.name} </span></p></Flex>
  <Flex textAlign="right"><p>Date Ordered : <span style={{color:"black",fontWeight:"bold"}}>{new Date(newpoinfo?.createdAt).toLocaleDateString()} </span></p></Flex>
  </Flexed>
  <Flexed borderBottom="1px solid grey" padding="0px 0px 15px 0px">
  <Flex textAlign="left"><p>Items Count : <span style={{color:"black",fontWeight:"bold"}}>{newpoinfo.items?.length} </span></p></Flex>
  <Flex textAlign="center"><b>Status:</b> {closed ? "CLOSED" : "OPEN"}</Flex>
  <Flex textAlign="right"><p>Delivery Scheduled : <span style={{color:"black",fontWeight:"bold"}}>{new Date(newpoinfo?.dueDate).toLocaleDateString()} </span></p></Flex>
  
  </Flexed>
  <div style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}><PrintComponent closedd={closed} id={newpoinfo.ponum} to={selectedVendor} items={items} total={total}  /></div>
  </div>
<div style={{position:"relative",width:"100%"}}>
 

<div className="" style={{width:"100%",overflowX:"scroll",border:"1px solid var(--plsGrey)",position:"relative"}}>

<table className="sortable hand" style={{width:"200%",borderCollapse:"collapse"}}>
<thead>
<tr style={{display:"flex",justifyContent:"flex-start"}}>

<th style={{flex:".3",padding:"3px 0px"}}></th>
<th style={{flex:"1",padding:"3px 0px"}}>Job#</th>
<th style={{flex:"2",padding:"3px 0px"}}>Item</th>

<th style={{flex:"1",padding:"3px 0px"}}>Ordered</th>
<th style={{flex:"1",padding:"3px 0px"}}>Recieved</th>
<th style={{flex:"1",padding:"3px 0px"}}>Cost</th>
<th style={{flex:".5",padding:"3px 0px",textAlign:"center"}} >Total</th>
<th style={{flex:"1",padding:"3px 0px 3px 30px"}}>Date Ordered</th>
<th style={{flex:"1",padding:"3px 0px"}}>Due Date</th>
<th style={{flex:"1",padding:"3px 0px"}}>Received Date</th>
<th style={{flex:".5",padding:"3px 0px"}}>Size</th>
<th style={{flex:".5",padding:"3px 0px"}}>Invoiced</th>
<th style={{flex:"3",padding:"3px 0px"}}>Notes</th>
</tr>
</thead>
 <tbody style={{height:"150px",fontSize:"1rem"}} id="polistitems">
 {items?.map((item,index) => {
  let colors = setrecitemcolor(item.status)
  return (
  <>
 
  <tr key={index} style={{display:"flex",borderBottom:"1px solid var(--plsGrey)",backgroundColor:colors.bg,color:colors.text}} className={receive === item.id ? 'active' : ''} onClick={() => {setSelectedItem(item)}}>
       <td style={{flex:".3",padding:"3px 0px 3px 5px",position:"relative"}}>{item.status !== "undefined" && <ConfirmationNumberIcon style={{position:"absolute",left:"0",top:"1px"}}/>}</td>
       <td onClick={() => {}} style={{flex:"1",padding:"3px 0px 3px 5px"}}>{item.jobnum}</td>
  <td onClick={() => {}} style={{flex:"2",padding:"3px 0px"}}>{item.itemname}</td>

  <td onClick={() => {}} style={{flex:"1",padding:"3px 0px"}}>{item.quantity}&nbsp;{item.orderby}</td>
  <td onClick={() => {}} style={{flex:"1",padding:"3px 0px"}}>{item?.recquantity}</td>
  <td onClick={() => {}} style={{flex:"1",padding:"3px 0px"}}>{currency(item.unitprice)} per {item.priceunit}</td>
  <td onClick={() => {}} style={{flex:".5",padding:"3px 0px",textAlign:"right"}}>{currency(item.extprice)}</td>
  <td onClick={() => {}} style={{flex:"1",padding:"3px 0px 3px 25px"}}>{new Date(newpoinfo?.createdAt).toLocaleDateString()}</td>
<td onClick={() => {}} style={{flex:"1",padding:"3px 0px"}}>{new Date(newpoinfo?.dueDate).toLocaleDateString()}</td>
<td onClick={() => {}} style={{flex:"1",padding:"3px 0px"}}>{item?.recDate && new Date(item.recDate).toLocaleDateString()}</td>
  <td onClick={() => {}} style={{flex:".5",padding:"3px 0px"}}>{item.length ? item.length+" x "+item.width : ""}</td>
  <td onClick={() => {}} style={{flex:".5",padding:"3px 0px"}}>{newpoinfo?.invoiceDate && new Date(newpoinfo?.invoiceDate).toLocaleDateString()}</td>
  <td onClick={() => {}} style={{flex:"3",padding:"3px 0px"}}>{item.recnotes}</td>
  </tr>  
  
  </>
)
})

}

 <tr style={{display:"flex"}}>

  <td style={{flex:"6.2",padding:"3px 0px",textAlign:"right"}}>Total:</td>
  <td style={{flex:".5",padding:"3px 0px",textAlign:"right"}}>{currency(total)}</td>
  <td style={{flex:"7",padding:"3px 0px"}}></td>
  </tr>  
</tbody>
<tfoot></tfoot></table>

</div>

<div style={{position:"relative"}}>

{closed && <div style={{width:"100%",height:"100%",backgroundColor:"rgb(0,0,0,.2)",position:"absolute",top:"0",left:"0",zIndex:"1",color:"white",display:"flex",justifyContent:"center",alignItems:"flex-start",paddingTop:"200px"}}>
  <div style={{transform:"rotate(-45deg)",fontSize:"calc(4rem + .5vw)",letterSpacing:"50px"}}>CLOSED</div>
  </div>}

{userInfo &&
 <form style={{position:"relative",border:"1px solid #648ca7",padding:"20px 5px"}} onSubmit={update}>




  {/*///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

<Flexed gap="10px">
<Flex flex="1"><Label>Item Type</Label><br />
  <Input type="text" list="vendorTypes" onChange={switchItem}  placeholder="Select Item" value={selectedItem.type} required />
<datalist id="vendorTypes" >
{
vendorTypes?.map(vendor => (
<option key={vendor.value} value={vendor.value} />
))
}
</datalist></Flex>
<Flex flex="3"><Label>Items:&nbsp; {itemList.length}</Label><br />
{itemList.length > 0 ? <><Input type="text" list="items" id="listofitems" onChange={setitem} value={selectedItem?.itemname} name="item" placeholder="Select Item" autoComplete="off" required/>
<datalist id="items">
{
 itemList?.map((item,index) => {
  switch(selectedItem.type){
    case "Sheet Stock":
      case "Roll Stock":
      case "Envelope":
return  <option key={index} data-item={item._id} data-cat="paper" value={item.name} />
        break;
        case "Ink":
    return  <option key={index} data-item={item._id} data-cat="ink" value={item.pressname+" "+item.type+" "+item.color} />
            break;
        default :
  }
})
}
</datalist>
</> 
:
<><Input type="text"  onChange={setitem} defaultValue={selectedItem?.itemname} name="item" placeholder="Enter Item" required/></>}


</Flex>


       
<Flex flex=".5"><Label>ID<br /><Input type="text" placeholder="Stock ID" value={selectedItem.stockid} readOnly/></Label></Flex>
<Flex flex=".7"><Label>Item Order By</Label><br />
<Input type="text" list="orderbylist" onChange={e => setSelectedItem({...selectedItem,orderby:e.target.value})} value={selectedItem.orderby} placeholder="Order By" required/>
<datalist id="orderbylist">
{
  orderbylist?.map((item,index) => (
    <option key={index} value={item} />
  ))
}
</datalist>
<Button type="button" style={{position:"absolute",bottom:"-25px",right:"0"}} onClick={() => updateItem(selectedItem.item,"defaultorderby",selectedItem.orderby)}>Update</Button></Flex>
</Flexed>

{selectedItem.type === "Sheet Stock"  ?  <Flexed  gap="10px" justifyContent="flex-start" margin="20px 0px 0px 0px"><Flex flex=".3"   textAlign="center"><Label>L</Label><br /><Input type="text" placeholder="Length" value={selectedItem.length} onChange={e => setSelectedItem({...selectedItem,length:e.target.value})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>
<Flex flex=".3"  textAlign="center"><Label>W</Label><br /><Input type="text" value={selectedItem.width} onChange={e =>  setSelectedItem({...selectedItem,width:e.target.value})} placeholder="Width"  /></Flex>
<Flex flex=".3"   textAlign="center"><Label>Weight</Label><br /><Input type="text" placeholder="Weight" value={selectedItem.weight} onChange={e => setSelectedItem({...selectedItem,weight:parseInt(e.target.value)})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>
<Flex flex=".3"   textAlign="center"><Label>Weight Basis</Label><br /><Input type="text" placeholder="Weight Basis" value={selectedItem.divide} onChange={e => setSelectedItem({...selectedItem,divide:e.target.value})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>
<Flex flex="1"></Flex></Flexed> : ''}

 <Flexed gap="10px" padding="15px 0px">
  <Flex flex=".7"><Label>Job #</Label><br />
      <Input type="text"  onChange={e => setSelectedItem({...selectedItem,jobnum:e.target.value})} value={selectedItem.jobnum} placeholder="Job #" />
</Flex>
    <Flex><Label>Quantity</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,quantity:parseInt(e.target.value)})} value={selectedItem?.quantity} placeholder="Quantity" /></Flex>
    <Flex ><Label>Unit Price</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,unitprice:parseFloat(e.target.value)})} value={selectedItem?.unitprice} placeholder="Unit Price" /><Button type="button" onClick={e => updateItem(selectedItem.item,"price",selectedItem.unitprice)}  style={{position:"absolute",bottom:"-25px",right:"0"}}>Update </Button></Flex>
    <Flex flex=".7"><Label>Price Unit</Label><br />
      <Input type="text" list="priceu" onChange={e => setSelectedItem({...selectedItem,priceunit:e.target.value})} value={selectedItem.priceunit} placeholder="Price Unit"  required/>
<datalist id="priceu">
{
  priceunits?.map((item,index) => (
    <option key={index} value={item} />
  ))
}
</datalist><Button type="button" onClick={e => updateItem(selectedItem.item,"defaultpriceunit",selectedItem.priceunit)} style={{position:"absolute",bottom:"-25px",right:"0"}}>Update</Button></Flex>
    <Flex><Label>Ext Price</Label><br /><Input type="number"  onChange={e => setSelectedItem({...selectedItem,extprice:e.target.value})} value={selectedItem?.extprice} placeholder="Ext Price" /></Flex>
  </Flexed>
  <Flexed  gap="10px" alignItems="flex-end">
    <Flex flex="1"><Label>Item Notes</Label><br /><Textarea rows="3"  onChange={e => setSelectedItem({...selectedItem,notes:e.target.value})} value={selectedItem.notes} ></Textarea></Flex>

    <Flex flex="3" display="flex" justifyContent="flex-end">
    {selectedItem.id && selectedItem.status > 1 ? <><Button type="button" onClick={manualPOclse} bgcolor="var(--plsBlue)"  color="white" style={{marginRight:"25px"}}>Process & Close PO</Button>
    <Button bgcolor="var(--plsBlue)"  color="white" style={{marginRight:"25px"}}>Process & Leave PO Open</Button></> : ""}
       {selectedItem.status !== 1 && <Button bgcolor="var(--plsBlue)"  color="white" style={{marginRight:"25px"}}>{selectedItem.id === "" ?  'Add Item' : 'Update Item' }</Button>} <Button bgcolor="yellow"  color="var(--plsBlue)" style={{marginRight:"50px",fontWeight:"bold"}} type="button" onClick={() => {setSelectedItem(initialSelectedItems);setReceive(null)}}>Clear Item</Button>
  {selectedItem.id && <DeleteForeverIcon style={{fontSize:"35px",color:"rgb(255,0,0,.6)"}}onClick={e => {deleteItem(selectedItem.id);setSelectedItem(initialSelectedItems);setReceive(null)}} />}</Flex>
  </Flexed>

 
  





    {/*////////////////////////////////////////////////////////////////////////////////////////////////*/}
</form>

}




<form style={{marginTop:"25px"}} >

<Grid grid='"edit ponum duedate podate notes notes" 
            "vendor vendor  vendorid type notes notes" 
            "address1 address1 address2 address2 notes notes" 
            "city state zip space notes notes" 
            "website website phone fax notes notes" 
            ' cols='repeat(6, 1fr)'>
<div className="edit" ><Button type="button" onClick={() => setEditvendor(true)}>Edit Vendor</Button></div>
<div className="edit" >{newpoinfo.vendor?.company.company_id !== selectedVendor.company?.company_id  ? <Button type="button" color="white" bgcolor="rgb(255,0,0,.5)" onClick={() => savenewvendor()}>Change Vendor</Button>: ""}</div>
<div className="vendor">Vendor: <br />
<Input  type="text" list="vendors"  value={selectedVendor.company?.name}  onChange={changevendor} required/>
<datalist id="vendors">
{
  vendorList?.map(vendor => (
    <option key={vendor._id} data-cid={vendor.company.company_id} value={vendor.company.name}/>
  ))
}
</datalist>
</div>
<div className="vendorid">Vendor ID: <br /><Input type="text"  value={selectedVendor.company?.company_id}  disabled={true}/></div>
<div className="type">Type <br />
<Input type="text" list="vendorTypes" value={selectedVendor?.type} placeholder="Select Type" required disabled={true}/>
<datalist id="vendorTypes">
{
  vendorTypes?.map(vendor => (
    <option key={vendor.value} value={vendor.value} />
  ))
}
</datalist></div>
<div className="space"></div>

<div className="address1">Address 1: <Input type="text"  value={selectedVendor.address?.address1} disabled={true}/></div>
<div className="address2">Address 2: <Input type="text" value={selectedVendor.address?.address2} disabled={true}/></div>
<div className="city">City: <Input type="text" value={selectedVendor.address?.city} disabled={true}/></div>
<div className="state">State: <Input type="text" value={selectedVendor.address?.state} disabled={true}/></div>
<div className="zip">Zip: <Input type="text" value={selectedVendor.address?.zip} disabled={true}/></div>
<div className="website">Website: <Input type="text" value={selectedVendor.website} disabled={true} /></div>
<div className="phone">Phone: <Input type="text" value={PhoneNumber(selectedVendor.numbers?.main)} disabled={true}/></div>
<div className="fax">Fax: <Input type="text" value={PhoneNumber(selectedVendor.numbers?.fax)} disabled={true}/></div>

<div className="notes">Notes:<Textarea rows="5"  onChange={() => alert()} value={ selectedVendor.notes }  disabled={true} /></div>
</Grid>


</form>
</div>
<hr style={{border:"1px solid inherit",marginTop:"15px"}} />

</div>
  </Wrap>

  )
}

export default PO