import { createContext, useContext, useState,useEffect} from "react";
import axios from 'axios'

import { io } from 'socket.io-client'



export const UserContext = createContext();



export const UserProvider = ({children}) => {

    const [userInfo, setUserInfo] = useState([])
 /*   const [socket, setSocket] = useState(null)

    useEffect(() => {
        setSocket(io("https://highgroundnotificationsocket.herokuapp.com"));  

    },[])

 useEffect(() => {
    if(userInfo.name){
        socket.emit("newUser", userInfo.name)
    }
  
 },[userInfo,socket])
 */
const LoginUser = async(loginInfo) => {

    await axios.post('https://highgroundapi.herokuapp.com/employee/login',loginInfo)
    .then(response => {
        console.log(response.data)
    const info = response.data.userinfo;
    setUserInfo({
      logged:true,
      name:info.name.firstName+" "+info.name.lastName.charAt(0),
      auth:info.auth,
      id:info._id
    })
   
    })
    .catch(err => console.log(err))
  
}



const LoginCheck = async({info}) => {

    await axios.post('http://localhost:5003/employee/login',info)
    .then(response => {
        console.log(response.data)
     /* const info = response.data.userinfo;
    setUserinfo({
      logged:true,
      name:info.name.firstName+" "+info.name.lastName.charAt(0),
      auth:info.auth
    })*/
    })
    .catch(err => console.log(err))
    }


    return (
        <UserContext.Provider value={{userInfo,LoginUser}}> 
        {children}
        </UserContext.Provider>
    )
}
