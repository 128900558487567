import { Flexed, Flex, Input, Wrap } from '../../Shared'
import styled from 'styled-components'



const Count = styled.div`
font-size:calc(3rem + .6vw);
text-align:center;
padding:0px 0px 10px 0px;
`;
const Text = styled.div``;
const Other = styled.div``;

const PoBoxes = ({text,count,countcolor}) => {
  return (
    <div style={{ border:"1px solid #c0c0c0c",boxShadow:"0px 0px 15px #c0c0c0 ",textAlign:"center",padding:"20px",minWidth:"135px"}} >
        <Count style={{color:countcolor}}>{count}</Count>
        <Text>{text}</Text>
        <Other></Other>
       </div>
  )
}

export default PoBoxes