import Modal from './Modal';
//import {Button} from '../../shared/Styles'

const ErrorModal = props => {
  return (
    <Modal
      onCancel={props.onClear}
      header={props.header}
      show={!!props.error}
      footer={<button onClick={props.onClear}>Okay</button>}
    >
      <p>{props.body}</p>
    </Modal>
  );
};

export default ErrorModal;
