import Table from "../../../../components/table/Table"
import { paperColumns } from '../../../../components/table/Columns'
import { useMemo,useState,useEffect } from 'react'
import axios from 'axios'
import {Flexed,Flex,Img,Input,Datepick,Label,currency,Textarea,Button,PhoneNumber,Selectbox} from '../../../../Shared'
import POnav from "./../../../po/newpo/POnav"
import "react-datepicker/dist/react-datepicker.css";
import PrintComponent from "../../PrintComponent"
import PrintMovement from "./PrintMovement"

const Movement = (props) => {


    const [DATA, setDATA] = useState([])
    const [newdata, setNewdata] = useState([])
    const [list, setList] = useState('')
    const [test, setTest] = useState([])
    const [start, setStart] = useState('')
    const [end, setEnd] = useState('')
    var today = new Date();
    today.setUTCHours(0,0,0,0);
console.log(today.toISOString())

const getdatastuff = async() => {
    await axios.get('http://localhost:5003/inv/items')
    .then(res => {setDATA(res.data);})
    .catch(err => console.log(err)) 
        }

useEffect(() => {
  
            getdatastuff() 
            setStart(new Date())
            setEnd(new Date())   
},[])


console.log(start)
    useEffect(() => {
        const getit = async() => {
            await axios.post(`http://localhost:5003/inv/movement`,info)
            .then(res =>setDATA(res.data))
            .catch(err => console.log(err))
           } 

let info = {
    startDate:start,
    endDate:end,
}
    switch(list){
        case 'in':
        info = {...info,id:"0"}
        getit()
        break;
        case 'out':
 info = {...info,id:"1"}
        getit()
        break;
        case 'in & out':
            info = {...info,id:"2"}
            getit()
        break;
        case 'moved':
            info = {...info,id:"3"}
            getit()
        break;
        case 'adjusted':
            info = {...info,id:"4"}
            getit()
        break;
      default:
        getdatastuff()
        break;
    }
  
   
 
    },[list,start,end])   


const re = () => {

}
console.log(DATA)
  return (
    <div style={{position:"relative"}}>
          <div style={{position:"sticky",top:"92px",backgroundColor:"#EDEEF2",zIndex:"3"}}>
          <Flexed>

<Flex fontSize="calc(1.3rem + .5vw)" padding="20px 0px">Inventory Movement</Flex>
</Flexed>

<POnav />

          </div>
          <div style={{position:"relative"}}>
          
       Total Items Found:&nbsp;{DATA.length}
       </div>
       <Flexed padding="10px 0px" gap="10px">
        <Flex textAlign="left">
            Movement:<br />
            <Selectbox width="90%" value={list} onChange={e => setList(e.target.value)}>
            <option value="">All</option>
            <option value="in">Inventory In</option>
            <option value="out">Inventory Out</option>
            <option value="in & out">Inventory In & Out</option>
            <option value="moved">Inventory Moved</option>
            <option value="adjusted">Adjusted</option>
            </Selectbox></Flex>
        <Flex textAlign="left">
            Start Date:<br /><Datepick selected={start} onChange={(date:Date) => setStart(date)}/>
        </Flex>
        <Flex textAlign="left">
       End Date:<br /><Datepick selected={end} onChange={(date:Date) => setEnd(date)} />
        </Flex>
        <Flex></Flex>
        <Flex><PrintMovement  list={DATA} what={list} start={start} end={end}/></Flex>
       </Flexed>
      
            <Table supplyColumns={paperColumns} DATA={DATA}  />
       
             </div>
  )
}

export default Movement