
import { UserContext } from '../context/UserContext';
import { Route, Routes} from 'react-router-dom'
import { useContext,useState,useEffect } from 'react';
import Navbar from '../components/Navbar';
import Navigate from '../components/Navigat';
import { Flex, Flexed } from '../Shared';
import Purchaseorders from './po/Purchaseorders';
import POshow from './purchase/POshow';
import Purchase from './po/newpo/Purchase';
import Addpo from './po/newpo//Addpo';
import Dash from './po/newpo/Dash';
import PO from './po/newpo/PO';
import Receive from './po/Receive';
import Supplies from './Supplies';
import Supplyitem from '../pages/supplies/Supplyitem';
import InksInv from './inks/InksInv';
import Inv from './po/newpo/inventory/Inv';
import Invitem from './po/newpo/inventory/InvItem'
import Movement from './po/newpo/inventory/Movement';






const Dashboard = () => {
    const [searchTerm, setSearchTerm] = useState("")
    const [comp, setComp] = useState("")
    const {userInfo,socket} = useContext(UserContext)



const handleit = () => {
alert()
socket.emit("sendNotification", {
    senderName:"PO System",
    msg:"PO Has Erros"
})

}

  return (

        <div style={{position:"relative",padding:"0px 20px",width:"100%",minHeight:"100vh",minWidth:"1269px"}}><Navbar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setComp={setComp} comp={comp} socket={socket} />
<Flexed>
    <div style={{width:"20%",maxWidth:"245px",position:"sticky", top:"87px",minHeight:"calc(100vh - 88px)",backgroundColor:"var(--plsBlue)",color:"white"}}>


    </div>


   <div style={{width:"100%",border:"1px solid red",padding:"0px 20px 20px 20px",}}>
{
    <Routes>
        <Route path="/" element={<Purchaseorders setSearchTerm={setSearchTerm} searchTerm={searchTerm} setComp={setComp} comp={comp} />} />
        <Route path="/po" element={<Purchaseorders setSearchTerm={setSearchTerm} searchTerm={searchTerm} setComp={setComp} comp={comp} />} />
        <Route path="/purchase" element={<Purchase />}>
            <Route path="new" element={<Addpo />} /> 
            <Route path="po" element={<PO />} /> 
            <Route path="" element={<Dash />} /> 
            <Route path="receive" element={<Receive />} /> 
        </Route>
        <Route path="/supplies" element={<Supplies />} />
        <Route path="/inventory" element={<Inv />} />
        <Route path="/inventory/:supplyid" element={<Invitem />} />
        <Route path="/inventory/movement" element={<Movement />} />
        <Route path="/supplies/:supplyid" element={<Supplyitem />} />
          
           <Route path="/inks" element={<InksInv />}>
       
           </Route>
    </Routes>
}

   </div>
   </Flexed>
        </div>
       
  )
}

export default Dashboard

