import {useEffect, useState } from 'react'
import { Flexed, Flex, Input, Wrap } from '../../../Shared'
import axios from 'axios'
import Moment from 'react-moment';
import PoBoxes from '../PoBoxes';
import TableSet from '../../../components/table/Tableset';
import { POHEADERS } from '../../../components/table/Headers'
import { Link } from 'react-router-dom';







const Dash = ({comp,setComp,setCurrentSelectedPo}) => {


const [dueToday, setDueToday] = useState([])
const [dueThisWeek, setDueThisWeek] = useState([])
const [openPos, setOpenPos] = useState([])
const [closedPos, setClosedPos] = useState([])
const [searchTerm, setSearchTerm] = useState("")
const [paperinventory, setPaperinventory] = useState([])
const [inkinventory, setInkinventory] = useState([])
const [suppliesinventory, setSuppliesinventory] = useState([])
const [unassigned, setUnassigned] = useState([])
const [LIST, setLIST] = useState([])

const date = new Date();


useEffect(() => {
    const getopenpos = async() => {
        //await axios.get('https://highgroundapi.herokuapp.com/po')
        await axios.get('https://highgroundapi.herokuapp.com/po/open')
        .then(res => {setOpenPos(res.data);setLIST(res.data)})
        .catch(err => console.log(err))
}
const gettoday = async() => {
    await axios.get('https://highgroundapi.herokuapp.com/po/gettoday')
    .then(res => {setDueToday(res.data)})
    .catch(err => console.log(err))
}
const getweek = async() => {
    await axios.get('https://highgroundapi.herokuapp.com/po/getweek')
    .then(res => setDueThisWeek(res.data))
    .catch(err => console.log(err))
}
const getclosedpos = async() => {
    //await axios.get('https://highgroundapi.herokuapp.com/po')
    await axios.get('https://highgroundapi.herokuapp.com/po/closed')
    .then(res => {setClosedPos(res.data)})
    .catch(err => console.log(err))
}
const getpaperInv = async() => {
    //await axios.get('https://highgroundapi.herokuapp.com/po')
    await axios.get('http://localhost:5003/inv/items')
    .then(res => {setPaperinventory(res.data)})
    .catch(err => console.log(err))
}
const getInkInv = async() => {
    //await axios.get('https://highgroundapi.herokuapp.com/po')
    await axios.get('http://localhost:5003/ink')
    .then(res => {setInkinventory(res.data)})
    .catch(err => console.log(err))
}
const getSuppliesInv = async() => {
    //await axios.get('https://highgroundapi.herokuapp.com/po')
    await axios.get('http://localhost:5003/supplies')
    .then(res => {setSuppliesinventory(res.data)})
    .catch(err => console.log(err))
}
const getUnassigned = async() => {
    const r = {"item":"Supplies"}
    await axios.get('http://localhost:5003/inv/unassigned')
    .then(res => {setUnassigned(res.data)})
    .catch(err => console.log(err))
}
getopenpos()
    gettoday()
    getweek()
    getclosedpos()
    getpaperInv()
    getInkInv()
    getSuppliesInv()
    getUnassigned()
},[])

console.log()

const setList = (list) => {
switch(list){
    case "thisweek":
        setLIST(dueThisWeek);
        break;
    case "open":
        setLIST(openPos);
        break;
        case "closed":
            setLIST(closedPos);
            break;
            default:
        setLIST(dueToday);
    
}
}

console.log(paperinventory)

  return (
<>
<div style={{marginBottom:"15px",}}></div>
<div style={{display:"flex",width:"100%",position:"relative",gap:"20px",justifyContent:"space-around"}}>
<div style={{flex:"1"}} onClick={() => setList("today")}><PoBoxes text="Today" count={dueToday.length} countcolor="#F7006D"  /></div>
<div style={{flex:"1"}} onClick={() => setList("thisweek")}><PoBoxes text="This Week" count={dueThisWeek.length} countcolor="#8B00F7" /></div>
<div style={{flex:"1"}} onClick={() => setList("open")}><PoBoxes text="Open Po's" count={openPos.length} countcolor="#648CA7" /></div>
<div style={{flex:"1"}} onClick={() => setList("closed")}><PoBoxes text="Closed PO's" count={closedPos.length} countcolor="#F7AE00" /></div>
<div style={{flex:"1"}}> <Link to="/inventory"><PoBoxes text="Paper" count={paperinventory.length} countcolor="#FD8A8A" /></Link></div>
<div style={{flex:"1"}}> <Link to="/inks"><PoBoxes text="Inks" count={inkinventory.length} countcolor="#FD8A8A" /></Link></div>
<div style={{flex:"1"}}> <Link to="/supplies"><PoBoxes text="Supplies" count={suppliesinventory.length} countcolor="#FD8A8A" /></Link></div>
<div style={{flex:"1"}}> <Link to="/unassigned"><PoBoxes text="Unassigned" count={unassigned.length} countcolor="#FD8A8A" /></Link></div>
</div>

<div style={{borderTop:"1px solid #648ca7",marginTop:"25px"}}>
<TableSet headers={POHEADERS} data={LIST} setComp={setComp} setCurrentSelectedPo={setCurrentSelectedPo}/>
</div>
</>
  )
}

export default Dash


