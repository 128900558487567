
import './App.css';
import { UserContext } from './context/UserContext';
import { useContext} from 'react';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';


function App() {

  const {userInfo} = useContext(UserContext)

  return (
    <Dashboard />// userInfo.logged ? <Dashboard /> : <Login/>
  )}
   
export default App;


 