import {useState,useEffect,useContext}  from 'react'
import {Flexed,Flex,Input,Datepick,Label,Selectbox,Tooltip,Duplicate,Save,Print,Edit,EditOff,Textarea,PhoneNumber,AddIcon,Button} from '../../Shared'
import styled from 'styled-components'
import ErrorModal from '../../components/Modal/ErrorModal'
import {UserContext} from '../../context/UserContext';
import axios from 'axios';
import { toast } from 'react-toastify';



const Wrap = styled.div`

.error{
    border:2px solid red;
}
`;



const Receive = ({onCancel,selectedItem,setRecerrorsid,setRecerrors,recerrors,po,items,id}) => {
 
  const date = new Date();
  const {userInfo,socket} = useContext(UserContext)
  const [ponum, setPonum] = useState(po)
    const [errors, setErros] = useState({
        active:false,
        header:"",
        message:"",
        item:""
      })
      const orderbylist= ["Sheets","LBS","Each","Case","Box","Barrel","Canister","Lot"]
    const [item, setItem] = useState(selectedItem);
    const [newitems, setNewitems] = useState(items);
    const [recselectedItem, setRecselectedItem] = useState(selectedItem);
    const [setbutton, setSetbutton] = useState(false)
const [newerrors, setNewerrors] = useState([])
const [newerrorsid, setNewerrorsid] = useState([])

console.log(selectedItem)

useEffect(() => {

    let neww;
    let length = parseFloat(selectedItem.length);
    let width = parseFloat(selectedItem.width);
    let weight = parseFloat(selectedItem.weight);
    let divide = parseFloat(selectedItem.divide);
    let a;
  
      switch(recselectedItem.priceunit){
        case "PER M":
          a = (recselectedItem.quantity / 1000) * recselectedItem.unitprice
          setRecselectedItem({...recselectedItem,extprice:parseFloat(a).toFixed(2)})
         /*   a = length * width * weight * 2;
            a /= divide;
            a *= selectedItem.quantity
            a /= 1000
            a *= selectedItem.unitprice
            a /= 100
            setSelectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})*/
            break;
            case "CWT":
              a = (recselectedItem.quantity / 100) * recselectedItem.unitprice
              setRecselectedItem({...selectedItem,extprice:parseFloat(a).toFixed(2)})
              break;
            default:
              a = recselectedItem.quantity * recselectedItem.unitprice;
              setRecselectedItem({...recselectedItem,extprice:parseFloat(a).toFixed(2)})
      }
    
      
    },[recselectedItem.quantity,recselectedItem.unitprice,recselectedItem.priceunit])

   



    const checkItemIn = async(e) => {
        e.preventDefault();
  let rerrors = [];
  let errorsid = [];
        if(item.width > 0 && parseFloat(item.width) !== parseFloat(recselectedItem.width)){
            rerrors.push({id:"width",message:"Sheets Width does not match ordered width"})
        errorsid.push("width")
        }
        if(item.length > 0 && parseFloat(item.length) !== parseFloat(recselectedItem.length)){
        rerrors.push({id:"length",message:"Sheets Length does not match ordered Length"})
          errorsid.push("length")
          }
          if(item.orderby !== recselectedItem.orderby){
            rerrors.push({id:"orderby",message:"Orderby does not match PO Orderby"})
            errorsid.push("orderby")
            }
          if(item.quantity !== parseFloat(recselectedItem.quantity)){
                rerrors.push({id:"quantity",message:"Quantity does not match quantity ordered"})
                errorsid.push("quantity")
                }
                if(parseFloat(item.unitprice) !== parseFloat(recselectedItem.unitprice)){
                  rerrors.push({id:"unitprice",message:"Unit price does not match PO unitprice"})
                  errorsid.push("unitprice")
                  }
                  if(parseFloat(item.extprice) !== parseFloat(recselectedItem.extprice)){
                    rerrors.push({id:"extprice",message:"Total does not match PO Total"})
                    errorsid.push("extprice")
                    }
                    setNewerrors(rerrors)
                    setNewerrorsid(errorsid)
                    setSetbutton(true)
        }







      
   

const sendErrors = () => {
  socket.emit("sendNotification", {
    senderName:"PO System",
    msg:`PO ${ponum} Has Discrepancies`,
    errors:newerrors
})

}




const savePO = async() => {
let newsize;

  if(recselectedItem.length){
newsize = recselectedItem.length+" x "+recselectedItem.width
  }
  if(recselectedItem.width && recselectedItem.length === ""){
    newsize = recselectedItem.width
      }

  let status
  if(newerrors.length > 0){
    status = 2
        }
        if(newerrors.length === 0){
          status = 1
              }
      const date = new Date().toISOString()
     
        const newItem = {...item,
          recDate:date,
          recitemname: recselectedItem.itemname , 
          reclength: recselectedItem.length , 
          recquantity: recselectedItem.quantity , 
          recunitprice: recselectedItem.unitprice ,
          recwidth: recselectedItem.width,
          recextprice: recselectedItem.extprice ,
          recnotes:recselectedItem.notes,
          recCondition:recselectedItem.condition,
          errors:newerrors,
          errorids:newerrorsid,
          status:status
        }
        const objIndex = items.findIndex((obj => obj.id === item.id));
        newitems[objIndex] = newItem

  const info = {
    id:id,
    value:newitems,
    feild:"items"
    }
   await axios.post("http://localhost:5003/po/updateitem",info)
     .then(res => {toast.success('Item Received')})
      .then(() => onCancel(true))
      .catch(err => console.log(err))//toast.warning(err.response.data.message))
  
  if(newerrors.length > 0){
    sendErrors()
  }

 




switch(selectedItem.type){
case "Text":
case "Board Stock":
case "Cover Stock":
case "Roll Stock":
 case "Envelope":
  const invitem = {
    paperid:selectedItem.item,
    stockid:selectedItem.stockid,
    location:"Unassigned",
    size:newsize,
    currentInventory:recselectedItem.quantity,
    cost:recselectedItem.extprice,
    orderby:selectedItem.orderby,
    po:id,
    conditionReceived:recselectedItem.condition,
    jobnum:selectedItem.jobnum,
    history:{id:0,description:`Received ${recselectedItem.quantity} ${selectedItem.orderby} `,date:new Date().toISOString()},
    type:selectedItem.type,
    coated:selectedItem.coated
  }
 console.log(invitem)
await axios.post("http://localhost:5003/inv/additem",invitem)
 .then(res => {console.log(res.data)})
  .then(() => onCancel(true))
 .catch(err => console.log(err))//toast.warning(err.response.data.message))
    break;
    case "Ink":
      const inkitem = {
        location:"Inks",
        invid:selectedItem.stockid,
        size:newsize,
        currentInventory:recselectedItem.quantity,
        cost:recselectedItem.extprice,
        orderby:selectedItem.orderby,
        po:id,
        conditionReceived:recselectedItem.condition,
        jobnum:selectedItem.jobnum,
        history:`Received ${recselectedItem.quantity} on ${date}`,
        type:selectedItem.type
      } 
      break;
      default:
       await axios.post(`http://localhost:5003/supplies/item/${selectedItem.item}`)
   .then(res => {
    const founditem = res.data
const newquantity = parseInt(founditem.instock) + parseInt(recselectedItem.quantity)


   })
   // .then(() => onCancel(true))
    .catch(err =>  {
      console.log(err)
      alert('not found')
      const item = {
        location:"Supplies",
        invid:selectedItem.stockid,
        size:newsize,
        currentInventory:recselectedItem.quantity,
        cost:recselectedItem.extprice,
        orderby:selectedItem.orderby,
        po:id,
        conditionReceived:recselectedItem.condition,
        jobnum:selectedItem.jobnum,
        history:`Received ${recselectedItem.quantity} on ${date}`,
        type:selectedItem.type
      } 
    })//toast.warning(err.response.data.message))
      break;
}
 


console.log(selectedItem)

}



  return (
    <Wrap>
      <ErrorModal error={errors?.active} onClear={() => setErros()} header={errors?.header}  body={errors?.message}/>
    <form onSubmit={e => checkItemIn(e)}>

    <Flexed gap="10px">
    <Flex flex="3"><Label>Item:</Label><br />
  
    <Input type="text"  value={selectedItem?.itemname} name="item" placeholder="Enter Item" disabled/>
    
    
    </Flex>
    <Flex flex=".7"><Label>Job #</Label><br />
          <Input type="text"  onChange={e => setRecselectedItem({...recselectedItem,jobnum:e.target.value})} value={recselectedItem.jobnum} placeholder="Job #" disabled/>
    </Flex>
           
    <Flex flex=".5"><Label>ID<br /><Input type="text" placeholder="Stock ID" value={selectedItem.stockid} disabled/></Label></Flex>
 
    </Flexed>
    
     <Flexed padding="15px 0px" alignItems="flex-start"  gap="10px">
        <Flex flex="1"><Label>Quantity{newerrorsid.includes('quantity') && <span style={{color:"red",fontWeight:"bold"}}>:   {selectedItem.quantity}</span>}</Label><br /><Input className={newerrorsid.includes('quantity') ? 'error' : ''} type="number" onFocus={() => {setNewerrors([])}} onChange={e => setRecselectedItem({...recselectedItem,quantity:parseInt(e.target.value)})}  placeholder="Quantity" required/></Flex>
<Flex><Label>Order By{newerrorsid.includes('orderby') && <span style={{color:"red",fontWeight:"bold"}}>:   {selectedItem.orderby}</span>}</Label><br /><Input className={newerrorsid.includes('orderby') ? 'error' : ''} type="text" list="orderbylist" onChange={e => setRecselectedItem({...recselectedItem,orderby:e.target.value})} value={recselectedItem.orderby} placeholder="Order By" required/>
<datalist id="orderbylist">
{
  orderbylist?.map((item,index) => (
    <option key={index} value={item} />
  ))
}
</datalist></Flex>
        <Flex flex="1"   textAlign="center"><Label>Length{newerrorsid.includes('length') && <span style={{color:"red",fontWeight:"bold"}}>:   {selectedItem.length}</span>}</Label><br /><Input className={newerrorsid.includes('length') ? 'error' : ''} type="text" placeholder="Length" value={recselectedItem.length} onChange={e => setRecselectedItem({...recselectedItem,length:e.target.value})} required={selectedItem.type === "Sheet Stock" ? true : false} /></Flex>

    <Flex flex="1"  textAlign="center"><Label>Width{newerrorsid.includes('width') && <span style={{color:"red",fontWeight:"bold"}}>:   {selectedItem.width}</span>}</Label><br /><Input className={newerrorsid.includes('width') ? 'error' : ''} type="text" value={recselectedItem.width} onChange={e =>  setRecselectedItem({...recselectedItem,width:e.target.value})} placeholder="Width"  /></Flex>
        <Flex flex="1"   textAlign="center"><><Label>Weight{newerrorsid.includes('weight') && <span style={{color:"red",fontWeight:"bold"}}>   {selectedItem.weight}</span>}</Label><br /><Input type="text" placeholder="Weight" value={selectedItem.weight} disabled/></></Flex>

        <Flex flex="1"   textAlign="center"><><Label>Cut Base Weight{newerrorsid.includes('divide') && <span style={{color:"red",fontWeight:"bold"}}>   {selectedItem.divide}</span>}</Label><br /><Input type="text" placeholder="Weight" value={selectedItem.divide} disabled/></></Flex>
      </Flexed>
      <Flexed alignItems="flex-start"  gap="10px">
        <Flex flex="2"><Label>Receiving Notes</Label><br /><Textarea rows="5"  onChange={e => setRecselectedItem({...recselectedItem,notes:e.target.value})} value={recselectedItem.notes} ></Textarea></Flex>
       <Flex>  <Flex ><Label>Unit Price{newerrorsid.includes('unitprice') && <span style={{color:"red",fontWeight:"bold"}}>:   {selectedItem.unitprice}</span>}</Label><br /><Input className={newerrorsid.includes('unitprice') ? 'error' : ''} type="number"  onChange={e => setRecselectedItem({...recselectedItem,unitprice:parseFloat(e.target.value)})} value={recselectedItem?.unitprice} placeholder="Unit Price" /></Flex></Flex>
      <Flex flex="1"><Label>Ext Price{newerrorsid.includes('extprice') && <span style={{color:"red",fontWeight:"bold"}}>:   {selectedItem.extprice}</span>}</Label><br /><Input className={newerrorsid.includes('extprice') ? 'error' : ''} type="number"  onChange={e => setRecselectedItem({...recselectedItem,extprice:e.target.value})} value={recselectedItem?.extprice} placeholder="Ext Price" /></Flex>
<Flex> <Label>Condition</Label><br /> <Selectbox defaultValue={recselectedItem.condition} placeholder="Chose a condition" onChange={e => setRecselectedItem({...recselectedItem,condition:e.target.value})} required>
<option value="">Select a condition</option>
            <option value="Good">Good</option>
            <option value="Fair">Fair</option>
            <option value="Damaged">Damaged</option>
            <option value="Returned">Returned</option>
        </Selectbox></Flex>
      </Flexed>
      <Flexed  gap="10px">
        {newerrors.length > 0 &&
        <Flex style={{fontWeight:"bold",color:"rgb(255,0,0,.7)"}}><br /><u>The following discrepancies have occured on this PO:</u><br />
            {
              newerrors.map((item,index) => (
           <p key={index}>{item.message}</p>
               ) )  
            }
        </Flex>}
        <Flex textAlign="right" padding="30px"><Button >Check To PO</Button><br /><br />
        {setbutton && <Button onClick={savePO} >{newerrors.length > 0 ? 'Process With Changes' : 'Process'}</Button> }
        </Flex>
      </Flexed>
      </form>
      </Wrap>
  )
}

export default Receive